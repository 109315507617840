import React, { useState } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Row, Col, Modal, notification } from 'antd';

import { updatePaymentInfo } from '../../api/RestApi';
import styles from './style.module.scss';

import { PaymentForm } from '..';

interface Props {
  handleCancel: Function;
  visible: boolean;
  customerId: string;
}

const UpdateCard: React.FC<Props> = ({ visible, handleCancel, customerId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onFinish = async (values) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus',
    );
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setIsLoading(false);
    } else {
      const paymentMethodId = paymentMethod.id;
      const cardDetail = paymentMethod.card;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        // const invoiceId = localStorage.getItem('latestInvoiceId');
        // retryInvoiceWithNewPaymentMethod({
        //   customerId,
        //   paymentMethodId,
        //   invoiceId,
        //   priceId,
        // });
      } else {
        // Create the subscription
        updateCardDetail({
          paymentMethodId,
          cardDetail,
          values,
        });
      }
    }
  };

  const updateCardDetail = ({ paymentMethodId, cardDetail, values }) => {
    const { cardHolderName, country, address, state, city } = values;
    const { exp_month, exp_year, funding, last4 } = cardDetail;
    const payload = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      card_details: {
        name: cardHolderName,
        country: country,
        address: address,
        state: state,
        city: city,
        last4: last4,
        expiry_month: exp_month,
        expiry_year: exp_year,
        funding: funding,
      },
    };
    setIsLoading(true);
    updatePaymentInfo(payload)
      .then((res) => {
        if (res.status === 200) {
          notification['success']({
            message: 'Successfully Updated Payment Card',
          });
          handleCancel();
        } else {
          setIsLoading(false);
          notification['error']({
            message: 'Error: Payment Card not Updated',
          });
          setError('Error While Updating Payment Card');
        }
      })

      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={'Update Payment Card'}
      bodyStyle={{ minHeight: 300 }}
      className={styles.vPortFoiloTypeN2}
    >
      <Row>
        <Col span={24}>
          <PaymentForm
            onFinish={onFinish}
            isLoading={isLoading}
            error={error}
            setError={setError}
            //  price={price}
            btnText="Update"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateCard;
