import React, { useState, useEffect } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { Pagination, Table } from '../../components';
import { getPerformanceData } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import { green, errorHandler, formatColumn } from '../../utils';
// import { columns } from '../Alerts/Alerts.Constants';
import FilterForm from '../reports/FilterForm';
import FilteredValues from '../trendingData/FilteredValues';
import styles from './style.module.scss';

interface Props {
  history: Object;
  setKey: Function;
  tabNum: string;
}

const Performance: React.FC<Props> = ({ history, setKey, tabNum }) => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState({});
  const [sortDetail, setSortDetail] = useState<Array>([]);
  const [filters, setFilters] = useState({});
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    let payload = {
      page: pageNo,
      size: pageSize,
      sortBy: sortDetail[0]?.sortBy
        ? sortDetail[0].sortBy
        : 'student_performance_rank',
      sortOrder: sortDetail[0]?.sortOrder ? sortDetail[0].sortOrder : 'asc',
      filters: filters,
    };
    const apis = [getPerformanceData(payload)];
    Promise.all(apis)
      .then((res) => {
        dispatch(setTableData(res[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [sortDetail, pageNo, pageSize, filters]);

  const onFilter = (val, sort) => {
    setFilters(val);
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
  };
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };

  const handleFilterValueClick = (name, value) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[name];
    setFilters(updatedFilters);
    setOpenFilterForm(false);
  };

  const handleReset = () => {
    setFilters({});
  };
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };
  const { data, columns, total } = tableData ? tableData : {};
  const handleSort = (sortBy, sortOrder) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };
  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    if (order === 'ascend') {
      handleSort(field, 'ASC');
    } else {
      handleSort(field, 'DESC');
    }
  };

  return (
    <div
      className={styles.clientContainer}
      style={{ marginTop: '20px !important' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div className={styles.filterValueBox}>
          {!isEmpty(filters) &&
            Object.values(filters).filter((d) => d).length > 0 && (
              <FilteredValues
                filterOptions={filters}
                handleClick={handleFilterValueClick}
                handleReset={handleReset}
              />
            )}
        </div>
        <Button
          icon={<FilterOutlined />}
          size={20}
          onClick={() => {
            setOpenFilterForm(!openFilterForm);
          }}
          //style={{ marginRight: 20 }}
          className={styles.Vfilterbtn}
          disabled={false}
          id="reportColumnFilters"
        >
          Filter/Sort
        </Button>
      </div>
      {(data?.length === 0 || isLoading) && (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={isLoading} size={50} />
        </div>
      )}
      {data?.length > 0 && !isLoading && (
        <Table
          columns={formatColumn(
            arrangeColumns(columns),
            true,
            sortDetail,
            history,
            false,
            null,
            'alert',
            null,
            null,
            theme,
          )}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          size="small"
          bordered={true}
          rowClassName={styles.tableHeader}
          className={styles.TabularData}
          onChange={handleTableOnChange}
          id="allPicksId"
          // sticky={true}
        />
      )}
      {openFilterForm && (
        <FilterForm
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          filterOptions={filters}
          reportCode={'student'}
          columns={arrangeColumns(columns)}
          sortDetail={[
            {
              sortBy: sortDetail[0]?.sortBy ? sortDetail[0]?.sortBy : '',
              sortOrder: sortDetail[0]?.sortOrder
                ? sortDetail[0]?.sortOrder
                : '',
            },
          ]}
          // handleSortReset={() => setSortDetail([])}
          // handleSortSubmit={(val) => setSortDetail(val)}
        />
      )}
      <div style={{ width: '100%' }}>
        <Pagination
          pageSize={pageSize}
          total={total}
          onChange={onPaginationChange}
          pageNo={pageNo}
          loading={isLoading}
          showPagination={true}
        />
      </div>
    </div>
  );
};

export default Performance;
