import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Collapse,
  Empty,
  List,
  Modal,
  notification,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  addSymbolToTsWatchlist,
  getIIRQuarters,
  getTSWatchlistData,
} from '../../graphql';
import { setIIRQuarters } from '../../redux/reducers/report';
import { useTheme } from '../../ThemeContext';
import {
  currencyFormat,
  errorHandler,
  formatTableData,
  formatTotalRow,
  getDisplayLastQtrByIdx,
  green,
} from '../../utils';
import SaveReport from './SaveReport';
import { getSearchSymbolTablesData } from './SearchReport.helper';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div
      className="custom-legend"
      style={{ display: 'flex', fontSize: '14px', justifyContent: 'center' }}
    >
      {payload.map((entry, index) => (
        <div
          key={`legend-item-${index}`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {/* Only render the legend text, without the icon */}
          <span style={{ color: '#8884d8', marginLeft: '8px' }}>
            {entry.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const formatLargeNumbers = (n: number) => {
  if (n === 0) return '0';

  const sign = n < 0 ? '-' : '';
  const absNumber = Math.abs(n);

  const denominations = [
    { value: 1e12, symbol: 'T' }, // Trillions
    { value: 1e9, symbol: 'B' }, // Billions
    { value: 1e6, symbol: 'M' }, // Millions
    { value: 1e3, symbol: 'K' }, // Thousands
    { value: 1, symbol: '' }, // Base case (no abbreviation)
  ];

  for (const { value, symbol } of denominations) {
    if (absNumber >= value) {
      return `${sign}${(absNumber / value).toFixed(1)}${symbol}`;
    }
  }

  return `${sign}${n}`;
};

// Custom Y-Axis tick component
const CustomYAxisTick = ({ x, y, payload }: any) => {
  const number = payload.value;

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fill="#1abd50"
      fontSize={14}
      fontWeight="bold"
    >
      {formatLargeNumbers(number)}
    </text>
  );
};

const SearchSymbolPage = () => {
  const {
    searchData = {},
    isLoading,
    reportInfo,
    currentSearchCriteria,
    reportType,
    reportSubType,
    reportTitle,
    fetchReportName,
    fetchingName,
    history,
    handleISRTOIIR,
    setIsLoading,
    setFilterOptions,
    handleISRTOStatusReport,
  } = useContext(SearchReportContext);
  const getCurrentQtrString = () => {
    const date = new Date();
    return (
      'Q' + (Math.floor(date.getMonth() / 3) + 1) + '-' + moment().format('YY')
    );
  };
  const { Option } = Select;

  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState(['1']);
  const [sortDetail, setSortDetail] = useState([]);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [isEpsChecked, setIsEpsChecked] = useState<boolean>(true);
  const [isWkAvgChecked, setIsWkAvgChecked] = useState<boolean>(true);
  const [isMthAvgChecked, setIsMthAvgChecked] = useState<boolean>(true);
  const [isHedgeInstChecked, setIsHedgeInstChecked] = useState<boolean>(true);
  const [isInstChecked, setIsInstChecked] = useState<boolean>(true);
  const [isDpChecked, setIsDpChecked] = useState<boolean>(true);
  const [quarterlyGraphType, setQuarterlyGraphType] = useState('Avg Price');
  const [epsGraphType, setEpsGraphType] = useState('Total Revenue');
  const [wkAvgGraphType, setWkAvgGraphType] = useState('Average');
  const [mthAvgGraphType, setMthAvgGraphType] = useState('Average');
  const [hedgeInstGraphType, setHedgeInstGraphType] = useState();
  const [instGraphType, setInstGraphType] = useState();
  const [dpGraphType, setDpGraphType] = useState('Price');
  const CustomCursor = (props) => {
    const { x, y, width, height } = props;

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="green" // You can set this to any color
        opacity={0.2} // Set the desired opacity here (e.g., 0.2)
        stroke="none"
        pointerEvents="none" // Ensures it doesn't interfere with mouse events
      />
    );
  };
  const {
    company = '',
    companyProfile = '',
    metMMCriteria = false,
  } = searchData;
  const { symbol } = reportInfo;

  const ISRTOIIIRHandler = (iirData) =>
    Modal.confirm({
      title: 'Open Individual Institution Report',
      content: (
        <p>{`Are you sure you want to open ${iirData.institutionsName[0]} Report ?`}</p>
      ),
      onOk: () => handleTelegramUnlink(iirData),
    });
  const statusParamsMap = {
    INCREASED: 'INCREASED',
    DECREASED: 'REDUCED',
    SOLD_OFF: 'SOLD OFF',
    NEWLY_BOUGHT: 'NEWLY BOUGHT',
    NO_CHANGE: 'NO CHANGE',
  };
  const ISRTOStatusReport = (statusData) =>
    Modal.confirm({
      title: 'Open Status Report',
      content: (
        <p>{`Are you sure you want to open ${
          statusParamsMap[statusData.multiStatus[0]]
        } Report ?`}</p>
      ),
      onOk: () => handleStatusReportRedirect(statusData),
    });

  const handleStatusReportRedirect = (statusData) => {
    setFilterOptions({
      multiStatus: statusData.multiStatus,
      symbols: statusData.symbols,
    });
    handleISRTOStatusReport();
    setTimeout(() => {
      history.push(`/search/report/` + queryString.stringify(statusData));
    }, 1000);
  };
  const handleTelegramUnlink = (iirData) =>
    getIIRQuarters({
      context: iirData.subtype.toUpperCase(),
      ciks: iirData.institutions,
    }).then((res) => {
      setIsLoading(true);
      dispatch(setIIRQuarters(iirData.institutions[0], res));
      // setQuartersOptions(res);
      // setSelectedQuarters(res[0].value);
      setFilterOptions({});
      handleISRTOIIR();
      setTimeout(() => {
        history.push(`/search/report/` + queryString.stringify(iirData));
      }, 1000);
    });
  const url = new URL(window.location.href);
  const fromReport = url.searchParams.get('fromReport');
  useEffect(() => {
    if (fromReport === 'status') {
      setTimeout(() => {
        onPanelChange(['13']);
      }, 500);
      setTimeout(() => {
        window.scrollTo(0, 600);
      }, 3000);
    }
  }, [fromReport]);

  function onCheckBoxChange() {
    setIsChecked(!isChecked);
  }

  function onDpCheckBoxChange() {
    setIsDpChecked(!isDpChecked);
  }

  function onEpsCheckBoxChange() {
    setIsEpsChecked(!isEpsChecked);
  }

  function onWkAvgCheckBoxChange() {
    setIsWkAvgChecked(!isWkAvgChecked);
  }

  function onMthAvgCheckBoxChange() {
    setIsMthAvgChecked(!isMthAvgChecked);
  }

  function onHedgeInstCheckBoxChange() {
    setIsHedgeInstChecked(!isHedgeInstChecked);
  }

  function onInstCheckBoxChange() {
    setIsInstChecked(!isHedgeInstChecked);
  }

  function onQuarterlyGraphTypeChange(key) {
    setQuarterlyGraphType(key);
  }

  function onDpGraphTypeChange(key) {
    setDpGraphType(key);
  }

  function onEpsGraphTypeChange(key) {
    setEpsGraphType(key);
  }

  function onWkAvgGraphTypeChange(key) {
    setWkAvgGraphType(key);
  }

  function onMthAvgGraphTypeChange(key) {
    setMthAvgGraphType(key);
  }

  function onHedgeInstGraphTypeChange(key) {
    setHedgeInstGraphType(key);
  }

  function onInstGraphTypeChange(key) {
    setInstGraphType(key);
  }

  function convertCurrencyToNumber(currencyString) {
    const multipliers = {
      K: 1e3, // Thousand
      M: 1e6, // Million
      B: 1e9, // Billion
      T: 1e12, // Trillion
    };

    const sign = currencyString.startsWith('-') ? -1 : 1;
    const cleanString = currencyString.replace(/[^0-9A-Za-z.,]/g, '');

    const match = cleanString.match(/^([\d.,]+)([A-Za-z]+)?$/);

    if (!match) {
      throw new Error('Invalid currency format');
    }

    let amount = parseFloat(match[1].replace(/,/g, ''));
    const suffix = match[2]?.toUpperCase();

    if (!suffix || !multipliers[suffix]) {
      return sign * amount;
    }

    return sign * amount * multipliers[suffix];
  }

  const quarterlyGraphTypes = {
    'Avg Price': 'avg_price',
    'Avg Volume': 'avg_volume',
    'Inst Counts': 'inst_counts',
    'Inst Shares': 'inst_shares',
    '% SHRS Change': 'perc_shares_change',
    'Approx Inv value': 'approx_inv_value',
    Price: 'price',
    'Price Diff': 'price_diff',
    'Price Diff %': 'price_diff_perc',
    Volume: 'volume',
    'Volume Diff': 'volume_diff',
    'Volume Diff %': 'volume_diff_perc',
    'Total Revenue': 'total_revenue',
    'Gross Income': 'Gross Income',
    'Net Income': 'Net Income',
    'EPS Estimate': 'eps_estimate',
    'EPS Actual': 'eps_actual',
    'EPS Difference': 'eps_difference',
    '% Difference': 'perc_difference',
    Average: 'average',
    'Dollars-Up': 'dollars_up',
    'Dollars-Down': 'dollars_down',
    'BottomLine ($Up - $Down)': 'bottom_line_(Up-Down)',
  };
  const quarterStrings = {
    lastQtr1: getDisplayLastQtrByIdx(1),
    lastQtr2: getDisplayLastQtrByIdx(2),
    lastQtr3: getDisplayLastQtrByIdx(3),
    lastQtr4: getDisplayLastQtrByIdx(4),
    lastQtr5: getDisplayLastQtrByIdx(5),
    lastQtr6: getDisplayLastQtrByIdx(6),
    lastQtr7: getDisplayLastQtrByIdx(7),
    lastQtr8: getDisplayLastQtrByIdx(8),
    lastQtr9: getDisplayLastQtrByIdx(9),
  };
  const getGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find((item) =>
      type === 'Approx Inv value'
        ? item.type?.props?.children?.trim() === type
        : item.type === type,
    );
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) =>
          reportName === 'quarterlyData'
            ? key.startsWith('lastQtr')
            : key.startsWith('lastDay'),
        ) // Filter only lastQtr keys
        .map((key) => ({
          name: reportName === 'quarterlyData' ? quarterStrings[key] : key,
          [graphTypeName]:
            quarterlyGraphType === 'Avg Price' ||
            quarterlyGraphType === '% SHRS Change'
              ? parseFloat(
                  dataForGraph[key].props.children.replace(/[^0-9.-]+/g, ''),
                )
              : quarterlyGraphType === 'Approx Inv value'
              ? Number(
                  convertCurrencyToNumber(dataForGraph[key].props.children),
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[quarterlyGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getDpGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find(
      (item) => item.type === type,
    );
    const transformObject = (input) => {
      return Object.entries(input).reduce((acc, [key, value]) => {
        const dataIndex = value.dataIndex;
        const title = value.title.props.children;

        // Set the new object with dataIndex as key and title as value
        acc[dataIndex] = title;
        return acc;
      }, {});
    };
    const outputObject = transformObject(dataForGraph1.columns);
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) => key.startsWith('lastDay')) // Filter only lastQtr keys
        .map((key) => ({
          name: outputObject[key],
          [graphTypeName]:
            dpGraphType === 'Price' ||
            dpGraphType === 'Price Diff' ||
            dpGraphType === 'Volume Diff' ||
            dpGraphType === 'Volume Diff %' ||
            dpGraphType === 'Price Diff %'
              ? parseFloat(
                  dataForGraph[key].props
                    ? dataForGraph[key].props?.children.replace(
                        /[^0-9.-]+/g,
                        '',
                      )
                    : 0,
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[dpGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getWkAvgGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find(
      (item) => item.type === type,
    );
    const transformObject = (input) => {
      return Object.entries(input).reduce((acc, [key, value]) => {
        const dataIndex = value.dataIndex;
        const title = value.title.props.children;

        // Set the new object with dataIndex as key and title as value
        acc[dataIndex] = title;
        return acc;
      }, {});
    };
    const outputObject = transformObject(dataForGraph1.columns);
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) =>
          key.startsWith(
            reportName === 'movingAvgs10Months' ? 'lastMonth' : 'lastWk',
          ),
        ) // Filter only lastQtr keys
        .map((key) => ({
          name: outputObject[key],
          [graphTypeName]:
            type === 'Average' ||
            type === 'Dollars-Up' ||
            type === 'BottomLine ($Up - $Down)' ||
            type === 'Dollars-Down'
              ? parseFloat(
                  dataForGraph[key].props
                    ? dataForGraph[key].props?.children.replace(
                        /[^0-9.-]+/g,
                        '',
                      )
                    : 0,
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value =
          reportName === 'movingAvgs10Months'
            ? item[quarterlyGraphTypes[mthAvgGraphType]]
            : item[quarterlyGraphTypes[wkAvgGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getEpsGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find((item) =>
      type === 'Total Revenue' ||
      type === 'Gross Income' ||
      type === 'Net Income'
        ? item.type?.props?.children?.trim() === type
        : item.type === type,
    );
    const transformObject = (input) => {
      return Object.entries(input).reduce((acc, [key, value]) => {
        const dataIndex = value.dataIndex;
        const title = value.title.props.children;

        // Set the new object with dataIndex as key and title as value
        acc[dataIndex] = title;
        return acc;
      }, {});
    };
    const outputObject = transformObject(dataForGraph1.columns);
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) => key.startsWith('lastQtr')) // Filter only lastQtr keys
        .map((key) => ({
          name: outputObject[key],
          [graphTypeName]:
            epsGraphType === 'Total Revenue' ||
            epsGraphType === 'Gross Income' ||
            epsGraphType === 'Net Income'
              ? Number(
                  dataForGraph[key].props?.children
                    ? convertCurrencyToNumber(dataForGraph[key].props?.children)
                    : 0,
                )
              : epsGraphType === 'EPS Actual' ||
                epsGraphType === 'EPS Estimate' ||
                epsGraphType === 'EPS Difference' ||
                epsGraphType === '% Difference'
              ? parseFloat(
                  dataForGraph[key].props?.children
                    ? dataForGraph[key].props.children.replace(/[^0-9.-]+/g, '')
                    : 0,
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[epsGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getHedgeInstGraphData = (TData, reportName, type) => {
    if (!type) {
      type = type
        ? type
        : TData.find((item) => item.name === reportName).data[0]
            .institutionName;
      reportName === 'instShares'
        ? setInstGraphType(
            TData.find((item) => item.name === reportName).data[0]
              .institutionName,
          )
        : setHedgeInstGraphType(
            TData.find((item) => item.name === reportName).data[0]
              .institutionName,
          );
    }
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find(
      (item) => item.institutionName === type,
    );
    const transformObject = (input) => {
      return Object.entries(input).reduce((acc, [key, value]) => {
        const dataIndex = value.dataIndex;
        const title = value.title.props.children;

        // Set the new object with dataIndex as key and title as value
        acc[dataIndex] = title;
        return acc;
      }, {});
    };
    const outputObject = transformObject(dataForGraph1.columns);
    const graphTypeName = type;
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) => key.startsWith('lastQtr')) // Filter only lastQtr keys
        .map((key) => ({
          name: outputObject[key],
          [graphTypeName]: Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[epsGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };

  let tablesData = getSearchSymbolTablesData(
    searchData,
    symbol,
    sortDetail,
    theme,
    history,
    ISRTOIIIRHandler,
    ISRTOStatusReport,
  );
  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const openAllPanel = () => {
    let keys = new Array(tablesData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };
  const tableLoading = {
    spinning: isLoading,
    indicator: (
      <span className={styles.spinnerStyle}>
        <BounceLoader color={green} loading={true} size={50} />
      </span>
    ),
  };

  const onTableChange = (pagination, filters, sorter, extra, key) => {
    const { field, order } = sorter;
    setSortDetail([
      {
        key: key,
        sortBy: field,
        sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
      },
    ]);
  };
  const addToTsWatchlist = async () => {
    console.log(symbol);
    getTSWatchlistData({
      noOfQtrs: 2,
    })
      .then(async (res) => {
        const symbolArray = (res.data || []).map((item) => item.symbol);
        if (symbolArray.includes(symbol)) {
          await notification.error({
            message: `${symbol} already exists in your Watch List`,
          });
        } else {
          const payload = {
            symbol: symbol,
            type: 'add',
          };
          const addSymbolResponse = await addSymbolToTsWatchlist(payload);
          if (addSymbolResponse.symbolAdded) {
            await notification.success({
              message: 'Symbol Added Successfully',
            });
          } else {
            notification.error({
              message: 'Failed to Add Symbol',
            });
          }
        }
      })
      .catch(errorHandler)
      .finally(() => {});
  };
  const getSortedData = (key, data) => {
    const sortObj = sortDetail.find((d) => d.key === key);
    if (sortObj) {
      const newData = data.sort((a, b) => {
        let keyA =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(a[sortObj.sortBy])
            : a[sortObj.sortBy];
        let keyB =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(b[sortObj.sortBy])
            : b[sortObj.sortBy];
        // Compare the 2 dates
        if (sortObj.sortOrder === 'ASC') {
          if (!isNaN(+keyA)) {
            return keyA - keyB;
          } else {
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }
        } else {
          if (!isNaN(+keyA)) {
            return keyB - keyA;
          } else {
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }
        }
      });

      // const newData = orderBy(
      //   data,
      //   [sortObj.sortBy],
      //   [sortObj.sortOrder.toLowerCase()],
      // );
      console.log(newData);
      return newData;
    }
    return data;
  };

  return (
    <div className={styles.isrReport}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: { type: reportType, subType: reportSubType },
                })
              : history.goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
        {!isLoading && (
          <>
            <div
              className={styles.SaveReportBoxNew2}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div>
                <p
                  className="ant-dropdown-link"
                  style={{ width: '180px', marginRight: '20px' }}
                  onClick={() => {
                    addToTsWatchlist();
                  }}
                >
                  <PlusOutlined /> Add To Watchlist
                </p>
              </div>
              <SaveReport
                searchCriteria={currentSearchCriteria}
                reportType={reportType}
                reportSubType={reportSubType}
                reportTitle={reportTitle}
                fetchReportName={fetchReportName}
                fetchingName={fetchingName}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <div className={styles.vSavedBlockedNew}>
          {!isLoading && (
            <>
              <p style={{ fontSize: 20, textTransform: 'uppercase' }}>
                {' '}
                {` Individual Statistical Report for (${symbol}) ${
                  company ? company : ''
                }`}
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://finance.yahoo.com/quote/${symbol}`}
              >
                (Yahoo Finance)
              </a>
            </>
          )}

          <div className={styles.titleContainer}>
            {/* <h2 style={{ marinTop: 0 }}>{title}</h2> */}
          </div>
        </div>
        {!isLoading && (
          <Paragraph ellipsis={{ rows: 2, expandable: true }}>
            <text
              style={{ cursor: 'text', userSelect: 'text' }}
              onCopy={() => navigator.clipboard.writeText(companyProfile)}
            >
              {companyProfile}
            </text>
          </Paragraph>
        )}
        {!isLoading && (
          <div
            hidden={!metMMCriteria}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <a
              style={{ textAlign: 'center' }}
              onClick={() => {
                history.push({
                  pathname: `/search/report/subtype=criteria_met&type=mark_minervini_strategy`,
                  state: { prevPage: 'search' },
                });
              }}
            >
              <text>{symbol} met MM Criteria Today Click here</text>
            </a>
          </div>
        )}

        {!isLoading && (
          <Row justify="end" span={24} style={{ marginBottom: 10 }}>
            {openKeys.length !== tablesData.length ? (
              <Button onClick={openAllPanel}>Open All</Button>
            ) : (
              <Button onClick={() => setOpenKeys([])}>Close All</Button>
            )}
          </Row>
        )}
      </div>
      <Row span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}

        {tablesData.length > 0 && (
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="left"
            style={{ width: '100%' }}
            activeKey={openKeys}
            onChange={onPanelChange}
          >
            {tablesData.map(
              (field, i) =>
                field.data.length > 0 && (
                  <Panel
                    style={{ color: 'red' }}
                    header={field.title.toUpperCase()}
                    key={i + 1}
                    id="vPanelChange"
                    // extra={
                    //   i === 0 ? (
                    //     <Button onClick={openAllPanel}>Open All</Button>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                  >
                    <div style={{ paddingRight: 400 }}>
                      {field.title.includes('Comparison by Quarterly Price') ? (
                        <>
                          <Checkbox
                            checked={isChecked}
                            onChange={onCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isChecked}
                            defaultValue={quarterlyGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onQuarterlyGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Avg Price'} value={'Avg Price'}>
                              {'Avg Price'}
                            </Option>
                            <Option key={'Avg Volume'} value={'Avg Volume'}>
                              {'Avg Volume'}
                            </Option>
                            <Option key={'Inst Counts'} value={'Inst Counts'}>
                              {'Inst Counts'}
                            </Option>
                            <Option key={'Inst Shares'} value={'Inst Shares'}>
                              {'Inst Shares'}
                            </Option>
                            <Option
                              key={'% SHRS Change'}
                              value={'% SHRS Change'}
                            >
                              {'% SHRS Change'}
                            </Option>
                            <Option
                              key={'Approx Inv value'}
                              value={'Approx Inv value'}
                            >
                              {'Approx Inv value'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes('10 Day Price Details For') ? (
                        <>
                          <Checkbox
                            checked={isDpChecked}
                            onChange={onDpCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isDpChecked}
                            defaultValue={dpGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onDpGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Price'} value={'Price'}>
                              {'Price'}
                            </Option>
                            <Option key={'Price Diff'} value={'Price Diff'}>
                              {'Price Diff'}
                            </Option>
                            <Option key={'Price Diff %'} value={'Price Diff %'}>
                              {'Price Diff %'}
                            </Option>
                            <Option key={'Volume'} value={'Volume'}>
                              {'Volume'}
                            </Option>
                            <Option key={'Volume Diff'} value={'Volume Diff'}>
                              {'Volume Diff'}
                            </Option>
                            <Option
                              key={'Volume Diff %'}
                              value={'Volume Diff %'}
                            >
                              {'Volume Diff %'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes('Quarterly Financials of') ? (
                        <>
                          <Checkbox
                            checked={isEpsChecked}
                            onChange={onEpsCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isEpsChecked}
                            defaultValue={epsGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onEpsGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option
                              key={'Total Revenue'}
                              value={'Total Revenue'}
                            >
                              {'Total Revenue'}
                            </Option>
                            <Option key={'Gross Income'} value={'Gross Income'}>
                              {'Gross Income'}
                            </Option>
                            <Option key={'Net Income'} value={'Net Income'}>
                              {'Net Income'}
                            </Option>
                            <Option key={'EPS Estimate'} value={'EPS Estimate'}>
                              {'EPS Estimate'}
                            </Option>
                            <Option key={'EPS Actual'} value={'EPS Actual'}>
                              {'EPS Actual'}
                            </Option>
                            <Option
                              key={'EPS Difference'}
                              value={'EPS Difference'}
                            >
                              {'EPS Difference'}
                            </Option>
                            <Option key={'% Difference'} value={'% Difference'}>
                              {'% Difference'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes(
                          '10 Moving Weeks Average Price Details For',
                        ) ? (
                        <>
                          <Checkbox
                            checked={isWkAvgChecked}
                            onChange={onWkAvgCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isWkAvgChecked}
                            defaultValue={wkAvgGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onWkAvgGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Average'} value={'Average'}>
                              {'Average'}
                            </Option>
                            <Option key={'Dollars-Up'} value={'Dollars-Up'}>
                              {'Dollars-Up'}
                            </Option>
                            <Option key={'Dollars-Down'} value={'Dollars-Down'}>
                              {'Dollars-Down'}
                            </Option>
                            <Option
                              key={'BottomLine ($Up - $Down)'}
                              value={'BottomLine ($Up - $Down)'}
                            >
                              {'BottomLine ($Up - $Down)'}
                            </Option>
                            <Option key={'Avg Volume'} value={'Avg Volume'}>
                              {'Avg Volume'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes(
                          '10 Moving Months Average Price Details For',
                        ) ? (
                        <>
                          <Checkbox
                            checked={isMthAvgChecked}
                            onChange={onMthAvgCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isWkAvgChecked}
                            defaultValue={wkAvgGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onMthAvgGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Average'} value={'Average'}>
                              {'Average'}
                            </Option>
                            <Option key={'Dollars-Up'} value={'Dollars-Up'}>
                              {'Dollars-Up'}
                            </Option>
                            <Option key={'Dollars-Down'} value={'Dollars-Down'}>
                              {'Dollars-Down'}
                            </Option>
                            <Option
                              key={'BottomLine ($Up - $Down)'}
                              value={'BottomLine ($Up - $Down)'}
                            >
                              {'BottomLine ($Up - $Down)'}
                            </Option>
                            <Option key={'Avg Volume'} value={'Avg Volume'}>
                              {'Avg Volume'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes('TOP-10-HEDGE FUNDS for') ? (
                        <>
                          <Checkbox
                            checked={isHedgeInstChecked}
                            onChange={onHedgeInstCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isHedgeInstChecked}
                            defaultValue={
                              tablesData.find(
                                (item) => item.name === 'top10InstShares',
                              ).data[0].institutionName || ''
                            }
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onHedgeInstGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            {tablesData
                              .find((item) => item.name === 'top10InstShares')
                              .data.map((country) => (
                                <Option
                                  key={country.institutionName}
                                  value={country.institutionName}
                                >
                                  {country.institutionName}
                                </Option>
                              ))}
                          </Select>
                        </>
                      ) : field.title.includes(
                          'TOP 10 INSTITUTIONS OWNING FOR',
                        ) ? (
                        <>
                          <Checkbox
                            checked={isInstChecked}
                            onChange={onInstCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isInstChecked}
                            defaultValue={
                              tablesData.find(
                                (item) => item.name === 'instShares',
                              ).data[0].institutionName || ''
                            }
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onInstGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            {tablesData
                              .find((item) => item.name === 'instShares')
                              .data.map((country) => (
                                <Option
                                  key={country.institutionName}
                                  value={country.institutionName}
                                >
                                  {country.institutionName}
                                </Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    {field.type === 'table' && (
                      <div
                        hidden={
                          (isChecked &&
                            field.title.includes(
                              'Comparison by Quarterly Price',
                            )) ||
                          (isDpChecked &&
                            field.title.includes('10 Day Price Details For')) ||
                          (isEpsChecked &&
                            field.title.includes('Quarterly Financials of')) ||
                          (isWkAvgChecked &&
                            field.title.includes(
                              '10 Moving Weeks Average Price Details For',
                            )) ||
                          (isMthAvgChecked &&
                            field.title.includes(
                              '10 Moving Months Average Price Details For',
                            )) ||
                          (isHedgeInstChecked &&
                            field.title.includes('TOP-10-HEDGE FUNDS for')) ||
                          (isInstChecked &&
                            field.title.includes(
                              'TOP 10 INSTITUTIONS OWNING FOR',
                            ))
                        }
                        className={styles.searchPaneTable}
                      >
                        <Table
                          scroll={{ x: true }}
                          loading={tableLoading}
                          columns={field.columns}
                          dataSource={formatTableData(
                            getSortedData(field.name, field.data),
                          )}
                          showSorterTooltip={false}
                          pagination={false}
                          size="small"
                          bordered={true}
                          rowClassName={styles.tableHeader}
                          className={styles.TabularData}
                          onChange={(a, b, c, d) =>
                            onTableChange(a, b, c, d, field.name)
                          }
                          id="allPicksId"
                          summary={
                            !field.summary
                              ? () => {}
                              : () => {
                                  return (
                                    <Table.Summary.Row>
                                      {formatTotalRow(field.summary[0]).map(
                                        (r, i) => (
                                          <Table.Summary.Cell index={i} key={i}>
                                            {r}
                                          </Table.Summary.Cell>
                                        ),
                                      )}
                                    </Table.Summary.Row>
                                  );
                                }
                          }
                        />
                        {field.name === 'ownersInfo' && (
                          <p
                            style={{
                              fontSize: 12,
                            }}
                          >
                            *Data Source{' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={field.dataSource}
                              style={{
                                textDecoration: 'underline',
                                fontSize: 12,
                                color: green,
                              }}
                            >
                              SEC
                            </a>
                          </p>
                        )}
                        {field.name === 'earningsData' && (
                          <p
                            style={{
                              fontSize: 12,
                              textAlign: 'center',
                              color: 'red',
                            }}
                          >
                            {' '}
                            {`Note: Date displayed  in ${getCurrentQtrString()} column is Expected Earnings Date`}
                          </p>
                        )}
                        {field.name === 'mutualFundQuarterlyShares' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                subtype:
                                  'SYMBOL_SUMMARY_MUTUAL_FUNDS'.toLowerCase(),
                                type: 'MUTUAL_FUNDS'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                        {field.name === 'insiderTransactions' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                type: 'SEC_INSIDERS'.toLowerCase(),
                                subtype:
                                  'INSIDER_DETAIL_SUMMARY_REPORT'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                      </div>
                    )}
                    {field.type === 'table' &&
                      field.title.includes('RECENT TRANSACTIONS') && (
                        <p
                          style={{
                            color: 'var(--whitecolor)',
                            marginLeft: '710px',
                            marginTop: '5px',
                          }}
                        >
                          {' '}
                          Total :{' '}
                          {currencyFormat(
                            field.total || 0,
                            false,
                            false,
                            2,
                            false,
                            true,
                          )}
                        </p>
                      )}
                    {/*{field.type === 'table' &&*/}
                    {/*  field.title.includes('Comparison by Quarterly Price') && (*/}
                    {/*    <p*/}
                    {/*      style={{*/}
                    {/*        color: 'var(--whitecolor)',*/}
                    {/*        marginLeft: '100px',*/}
                    {/*        marginTop: '5px',*/}
                    {/*        fontSize: '10px',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Formula For Approx Invested Value : (Shares Diff * Qtr*/}
                    {/*      Avg Price)*/}
                    {/*    </p>*/}
                    {/*  )}*/}
                    {field.type === 'table' &&
                      field.title.includes('Comparison by Quarterly Price') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isChecked}
                        >
                          <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={getGraphData(
                                tablesData,
                                'quarterlyData',
                                quarterlyGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={
                                  quarterlyGraphTypes[quarterlyGraphType]
                                }
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes('10 Day Price Details For') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isDpChecked}
                        >
                          <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={getDpGraphData(
                                tablesData,
                                'dailyPrices',
                                dpGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={quarterlyGraphTypes[dpGraphType]}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes('Quarterly Financials of') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isEpsChecked}
                        >
                          <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={getEpsGraphData(
                                tablesData,
                                'financialData',
                                epsGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={quarterlyGraphTypes[epsGraphType]}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes(
                        '10 Moving Weeks Average Price Details For',
                      ) && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isWkAvgChecked}
                        >
                          <ResponsiveContainer width="86%" height={300}>
                            <BarChart
                              data={getWkAvgGraphData(
                                tablesData,
                                'movingAvgs10Weeks',
                                wkAvgGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={quarterlyGraphTypes[wkAvgGraphType]}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes(
                        '10 Moving Months Average Price Details For',
                      ) && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isWkAvgChecked}
                        >
                          <ResponsiveContainer width="70%" height={300}>
                            <BarChart
                              data={getWkAvgGraphData(
                                tablesData,
                                'movingAvgs10Months',
                                mthAvgGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={quarterlyGraphTypes[mthAvgGraphType]}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes('TOP-10-HEDGE FUNDS for') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isHedgeInstChecked}
                        >
                          <ResponsiveContainer width="70%" height={300}>
                            <BarChart
                              data={getHedgeInstGraphData(
                                tablesData,
                                'top10InstShares',
                                hedgeInstGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                dataKey={hedgeInstGraphType}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes(
                        'TOP 10 INSTITUTIONS OWNING FOR',
                      ) && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isInstChecked}
                        >
                          <ResponsiveContainer width="70%" height={300}>
                            <BarChart
                              data={getHedgeInstGraphData(
                                tablesData,
                                'instShares',
                                instGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend content={<CustomLegend />} />
                              <Bar
                                fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                                dataKey={instGraphType}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}

                    {field.type === 'news' && (
                      <List
                        size="small"
                        // header={}
                        bordered
                        dataSource={field.data}
                        renderItem={(item: {}) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.url}
                                  >
                                    <p className={styles.newsTitle}>
                                      {item.title}
                                    </p>
                                  </a>

                                  <p className={styles.news_time}>
                                    {`${item.source} - ${moment(item.timestamp)
                                      .tz('America/New_York')
                                      .format('hh:mm A')},

                                ${moment(item.timestamp)
                                  .tz('America/New_York')
                                  .format('DD MMM YYYY')}`}
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Panel>
                ),
            )}
          </Collapse>
        )}
      </Row>
      <Row span={24} justify="center">
        {tablesData.length === 0 && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    </div>
  );
};

export default SearchSymbolPage;
