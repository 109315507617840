import React, { useEffect, useState } from 'react';

import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { getTelegramLinkUrl } from '../../api/RestApi';
import {
  getQuarters,
  getSectors,
  getIndustries,
  getNoOfWeeksDropdown,
  getNoOfWeeksForWeeklyYTDDropdown,
  getNoOfDowDaysForPriceAndVolDropdown,
  getCustomerSubscriptionInfo,
  getUserPortfolios,
  getUserPersonalInfo,
  getStudentInfo,
  getPrices,
  getFilerNames,
  getCountries,
  getInsiderTitles,
  getSeriesNames,
  getMutualFundSymbols,
  getMutualFundFundSymbols,
  getNoOfDowdaysDropdown,
} from '../../graphql';
import { setUserPortfoliosList } from '../../redux/reducers/portfolio';
import {
  setReportQuarters,
  setReportSectors,
  setReportIndustries,
  setReportNoOfWeeks,
  setReportNoOfDowDays,
  setReportFilerNames,
  setReportInsiderTitles,
  setReportMutualFundSymbols,
  setReportMutualFundFundSymbols,
  setReportSeriesNames,
  setCountries,
  setWeeklyYTDNoOfWeeks,
  setReportNoOfDowDaysForVolume,
} from '../../redux/reducers/report';
import {
  setStripeInfo,
  setUserProfile,
  setJoinTelegramLink,
  setPersonalData,
  setStudentInfo,
} from '../../redux/reducers/user';
import { green, errorHandler } from '../../utils';
import styles from './styles.module.scss';

interface Props {}
const SuccessMessage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { telegramId, personalData } = useSelector(
    ({ user }) => ({
      telegramId: get(user, 'personalData.additionalInfo.telegram_id', ''),
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const userType = personalData['type'];
  const isStudent = userType === 'student';
  useEffect(() => {
    setLoading(true);
    const promises = [
      getQuarters(),
      getSectors(),
      getNoOfWeeksDropdown(),
      getUserPersonalInfo(),
      getPrices({}),
      getCustomerSubscriptionInfo(),
      getStudentInfo(),
      getIndustries(),
      getUserPortfolios(),
      getFilerNames(),
    ];
    promises.push(getMutualFundSymbols());
    promises.push(getSeriesNames());
    promises.push(getMutualFundFundSymbols());
    promises.push(getInsiderTitles());
    promises.push(getTelegramLinkUrl());
    promises.push(getCountries());
    promises.push(getNoOfDowDaysForPriceAndVolDropdown());
    promises.push(getNoOfDowdaysDropdown());
    promises.push(getNoOfWeeksForWeeklyYTDDropdown());
    Promise.all(promises)
      .then((res) => {
        const {
          id = '',
          first_name = '',
          last_name = '',
          email = '',
          type = '',
          additionalInfo = {},
          userBillingAddress = {},
        } = res[3];
        const data = {
          userBillingAddress: userBillingAddress ? userBillingAddress : {},
          additionalInfo: additionalInfo ? additionalInfo : {},
          id: id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          type: type,
          date: new Date(),
        };
        dispatch(setReportQuarters(res[0]));
        dispatch(setReportSectors(res[1]));
        dispatch(setReportNoOfWeeks(res[2]));
        dispatch(setPersonalData(data));
        dispatch(setStripeInfo(res[4]));
        dispatch(setUserProfile(res[5]));
        if (res[6]) {
          dispatch(dispatch(setStudentInfo(res[6]['studentInfo'])));
        }
        dispatch(setReportIndustries(res[7]));
        dispatch(setUserPortfoliosList(res[8]));
        dispatch(setReportFilerNames(res[9]));
        dispatch(setReportMutualFundSymbols(res[10]));
        dispatch(setReportSeriesNames(res[11]));
        dispatch(setReportMutualFundFundSymbols(res[12]));
        dispatch(setReportInsiderTitles(res[13]));
        if (res[14]) {
          dispatch(
            setJoinTelegramLink(get(res[14], 'data.deepLinkingUrl', '')),
          );
        }
        dispatch(setCountries(res[15]));
        dispatch(setReportNoOfDowDaysForVolume(res[16]));
        dispatch(setReportNoOfDowDays(res[17]));
        dispatch(setWeeklyYTDNoOfWeeks(res[18]));
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dashboard}>
      {loading ? (
        <span className={styles.spinnerStyle}>
          <BounceLoader color={green} loading={true} size={200} />
        </span>
      ) : (
        <Redirect
          to={
            isStudent
              ? '/portfolio/Portfolio/virtual'
              : telegramId
              ? '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20'
              : '/community'
          }
        />
      )}
    </div>
  );
};

export default SuccessMessage;
