import React, { useState } from 'react';

import { Modal, Button, Form, Row, Col, Select, notification } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import { addSymbolToTsWatchlist, esSearchCompany } from '../../graphql';
import { green, red, allStatusOptions } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  visible: boolean;
  handleCancel: Function;
  data: Array<{}>;
}

const TSWatchlistSymbol: React.FC<Props> = ({
  visible,
  handleCancel,
  data,
}) => {
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState([]);
  // const [addSymbolLoad, setAddSymbolLoad] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  // const [modalError, setModalError] = useState<boolean>(false);
  const [error, setError] = useState('');

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      // const filterRes = res.filter((r) => !addedSymbols.includes(r.symbol));
      setSymbolData(res);
    }
    setFetching(false);
  };

  const onFinish = async (values) => {
    let { symbols } = values;
    const symbolArray = (data?.data || []).map((item) => item.symbol);
    for (let i = 0; i < symbols.length; i++) {
      if (symbolArray.includes(symbols[i])) {
        setError(`${symbols[i]} already exists in your Watch List`);
        notification.error({
          message: `${symbols[i]} already exists in your Watch List`,
        });
        // setModalError(true);
      } else {
        const payload = {
          symbol: symbols[i],
          type: 'add',
        };
        form.resetFields();
        handleCancel();
        const addSymbolResponse = await addSymbolToTsWatchlist(payload);
        if (addSymbolResponse.symbolAdded) {
          await notification.success({
            message: `${symbols[i]} Added Successfully`,
          });
          handleCancel();
          if (i === symbols.length - 1) {
            setTimeout(function () {
              window.location.reload();
            }, 5000);
          }
          // setAddSymbolLoad(false);
        } else {
          notification.error({
            message: `Failed to Add ${symbols[i]}`,
          });
          handleCancel();
        }
      }
    }
  };
  return (
    <Modal
      title={`Add Symbol`}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Form
        name={`Add Symbol`}
        onFinish={onFinish}
        labelCol={{ span: 24, padding: 0 }}
        wrapperCol={{ span: 24 }}
        form={form}
        initialValues={{
          sharesActivity: allStatusOptions[0].value,
        }}
        onValuesChange={() => setError('')}
      >
        <Row span={24}>
          <Col span={24}>
            <Form.Item
              name="symbols"
              label={'Enter Symbols'}
              className={styles.vFormSearhNew}
            >
              <Select
                placeholder="Enter valid symbols"
                mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setSymbolData([])}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {error && <span style={{ color: red }}>{error}</span>}
        <Row span={24} justify="start">
          <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default TSWatchlistSymbol;
