import React, { useEffect, useState } from 'react';

// import BounceLoader from 'react-spinners/BounceLoader';
//
// import { green } from '../../utils';
import { Checkbox, Col, Form, Input, Modal, notification } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  unsubscribeFromMails,
  unsubscribeMailPreferences,
} from '../../graphql';
import { green } from '../../utils';
import styles from '../signup/style.module.scss';

const UnSubscribe: React.FC<Props> = ({ history }) => {
  const [form] = Form.useForm();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const email = token ? Buffer.from(token, 'base64').toString() : '';
  const [isTS, setIsTS] = useState(false);
  const [isIA, setIsIA] = useState(false);
  const [isSEC, setIsSEC] = useState(false);
  const [isPA, setIsPA] = useState(false);
  const [isWG, setIsWG] = useState(false);
  const [isMC, setIsMC] = useState(false);
  const [isINST, setIsINST] = useState(false);
  const [isAll, setIsAll] = useState(false);
  useEffect(() => {
    setLoading(true);
    unsubscribeMailPreferences({ email: email }).then((res) => {
      setLoading(false);
      // setIsLoading(false);
      console.log('res', res);
      setIsIA(res.ia_subscribed);
      setIsTS(res.ts_subscribed);
      setIsSEC(res.sec_subscribed);
      setIsPA(res.pa_subscribed);
      setIsWG(res.wg_subscribed);
      setIsMC(res.mc_subscribed);
      setIsINST(res.inst_subscribed);
      setIsAll(res.mail_subscribed);
    });
  }, [email]);
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (values) => {
    let { email } = values;
    // alert(`${email} has been Unsubscribed`);
    const payload = {
      email: email,
      ts_subscribed: isAll || isTS,
      sec_subscribed: isAll || isSEC,
      ia_subscribed: isAll || isIA,
      pa_subscribed: isAll || isPA,
      wg_subscribed: isAll || isWG,
      mc_subscribed: isAll || isMC,
      inst_subscribed: isAll || isINST,
      mail_subscribed: isAll,
    };
    form.resetFields();
    setLoading(true);
    const unSubscribeResponse = await unsubscribeFromMails(payload);
    if (unSubscribeResponse.unSubscribed) {
      notification.success({
        message: 'Saved Preferences Successfully',
      });
      setLoading(false);
      history.push(`/unsubscribe/`);
      window.location.reload();
    } else {
      notification.error({
        message: 'Failed to Saved Preferences ',
      });
    }
  };
  const onTSChange = (e) => {
    setIsTS(e.target.checked);
  };
  const onIAChange = (e) => {
    setIsIA(e.target.checked);
  };
  const onSECChange = (e) => {
    setIsSEC(e.target.checked);
  };
  const onPAChange = (e) => {
    setIsPA(e.target.checked);
  };
  const onWGChange = (e) => {
    setIsWG(e.target.checked);
  };
  const onMCChange = (e) => {
    setIsMC(e.target.checked);
  };
  const onINSTChange = (e) => {
    setIsINST(e.target.checked);
  };
  const onAllChange = (e) => {
    setIsAll(e.target.checked);
    setIsTS(e.target.checked);
    setIsPA(e.target.checked);
    setIsSEC(e.target.checked);
    setIsWG(e.target.checked);
    setIsMC(e.target.checked);
    setIsINST(e.target.checked);
    setIsIA(e.target.checked);
  };
  const confirmPopup = (values) =>
    Modal.confirm({
      title: 'Open Status Report',
      content: (
        <>
          <p>{`Confirm Your Mail Preferences !`}</p>
          <ul>
            <li hidden={!isAll}>{isAll ? 'ALL' : ''}</li>
            <li hidden={isAll || !isTS}>
              {isTS ? 'Trending Stock Alerts' : ''}
            </li>
            <li hidden={isAll || !isIA}>{isIA ? 'Insider Alerts' : ''}</li>
            <li hidden={isAll || !isSEC}>{isSEC ? 'Sec13DG Alerts' : ''}</li>
            <li hidden={isAll || !isPA}>{isPA ? 'Portfolio Alerts' : ''}</li>
            <li hidden={isAll || !isWG}>
              {isWG ? 'ThinkSabio Weekly Gems' : ''}
            </li>
            <li hidden={isAll || !isMC}>
              {isMC ? 'ThinkSabio Occasion Mails' : ''}
            </li>
            <li hidden={isAll || !isINST}>
              {isINST ? 'ThinkSabio Weekly Institution Stock insights' : ''}
            </li>
          </ul>
        </>
      ),
      onOk: () => onFinish(values),
    });

  return !loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '670px',
        width: '100%',
      }}
    >
      <div className={styles.showVerify}>
        <div className={styles.verifiedBoxContent}>
          <img
            alt="mail_icon"
            src="https://thinksabio-asset.s3.amazonaws.com/Mailer-icon.png"
          />
          <h3
            style={{
              color: 'var(--whitecolor)',
            }}
          >
            You are almost there.
          </h3>
          <p
            style={{
              color: 'var(--whitecolor)',
            }}
          >
            Please provide your email address.
          </p>
          <Form
            form={form}
            name="unsubscribe"
            onFinish={confirmPopup}
            initialValues={{ email: email }}
          >
            <div style={{ margin: 'auto', width: 200 }}>
              <Form.Item
                // hasFeedback
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please enter valid Email!',
                  },
                ]}
              >
                <Input
                  placeholder={'Please Enter Your Email Id'}
                  autoComplete="false"
                />
              </Form.Item>
            </div>
            <Col>
              <p
                style={{
                  color: 'var(--whitecolor)',
                }}
              >
                Which of the following emails you would prefer to receive from
                us?
              </p>
              <br />
              {/*<Form.Item name="Alerts" label="">*/}
              {/*  <Radio.Group>*/}
              {/*    <Radio value="ALL">All</Radio>*/}
              {/*    <Radio value="TS">Trending Stokcs Alerts</Radio>*/}
              {/*    <Radio value="SC">Sec13DG Alerts</Radio>*/}
              {/*    <Radio value="IN">Insider Alerts</Radio>*/}
              {/*  </Radio.Group>*/}
              {/*</Form.Item>*/}
              <div
                style={{
                  border: '1px solid green',
                  margin: 'auto',
                  paddingTop: '20px',
                  width: '700px',
                }}
              >
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Form.Item name="ALL" style={{ marginLeft: '10px' }}>
                    <Checkbox checked={isAll} onChange={onAllChange}>
                      All
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="TS" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isTS}
                      onChange={onTSChange}
                    >
                      Trending Stocks Alerts
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="IA" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isIA}
                      onChange={onIAChange}
                    >
                      Insider Alerts
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="SEC" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isSEC}
                      onChange={onSECChange}
                    >
                      Sec13DG Alerts
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="PA" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isPA}
                      onChange={onPAChange}
                    >
                      Portfolio Alerts
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Form.Item name="MC" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isMC}
                      onChange={onMCChange}
                    >
                      ThinkSabio Occasion Mails
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="WG" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isWG}
                      onChange={onWGChange}
                    >
                      ThinkSabio Weekly Gems
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="INST" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isINST}
                      onChange={onINSTChange}
                    >
                      ThinkSabio Institution Stock Insights
                    </Checkbox>
                  </Form.Item>
                </Col>
              </div>
              {/*<Form.Item label="" name="otherInput">*/}
              {/*    <textarea*/}
              {/*      style={{*/}
              {/*        width: 500,*/}
              {/*        height: 50,*/}
              {/*        marginLeft: '20px',*/}
              {/*        marginTop: '10px',*/}
              {/*      }}*/}
              {/*    />*/}
              {/*</Form.Item>*/}
            </Col>
            <br />
            <button
              type="submit"
              style={{
                width: '100px',
                height: '40px',
                color: 'white',
                backgroundColor: 'green',
                cursor: 'pointer',
              }}
            >
              Submit
            </button>
          </Form>
        </div>
      </div>
      {/*<BounceLoader color={green} loading={true} size={250} />*/}
    </div>
  ) : (
    <div className={styles.loaderStyle} style={{ height: '670px' }}>
      <BounceLoader color={green} loading={loading} size={150} />
    </div>
  );
};

export default UnSubscribe;
