import React, { useState, useCallback, useContext } from 'react';

import { FilterOutlined, SettingOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Select, Tabs, Checkbox } from 'antd';
import {
  ColumnVisibility,
  PieChart,
  DraggableModal,
  DOWJonesFilter,
  DaysDropDown,
} from 'components';
import { debounce, get } from 'lodash';
import moment from 'moment-timezone';
import { shallowEqual, useSelector } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  durationOptions,
  getCurrentSECDateRange,
  green,
  statusTxnOptions,
} from 'utils/index';
import { noOfQtrList, typeOptions, categoryOptions } from 'utils/index';

import { getFilerNames } from '../../graphql';
import FilteredValues from './FilteredValues';
import FilterForm from './FilterForm';
import { ReportContext } from './ReportContext';
import {
  topOptions,
  specialOptions,
  getReportTitle,
  NonClickAbleFilter,
} from './Reports.constants';
import styles from './style.module.scss';

const { Option } = Select;
const { TabPane } = Tabs;

const ReportFilter = ({ lastUpdatedTime }) => {
  const {
    changeSearchText,
    isLoading,
    onFilter,
    tableColumns,
    onColumnFilter,
    reportCode,
    quartersOptions = [],
    onQuarterChange,
    reportType,
    filterOptions,
    cik,
    searchText,
    setCik,
    onNoOfQtrsChange,
    onCriteriaChange,
    sortDetail,
    onNoOfWeeksChange,
    onNoOfDaysChange,
    noOfWeeksList,
    noOfDaysList,
    onTypeChange,
    onCategoryChange,
    onDurationChange,
    onStatusTxnChange,
    onFilerNameChange,
    onSymbolChange,
    selectedQuarter,
    trendingStockType,
    setTrendingStockType,
    onSPChange,
    onNasdaqChange,
    onDOWJONESChange,
    onSoryByVolChange,
    sectorAllocation,
    noOfDaysForVolumeList,
  } = useContext(ReportContext);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [openColumnFilter, setOpenColumnFilter] = useState<boolean>(false);
  const [showAllocation, setShowAllocation] = useState<boolean>(false);
  const [fundNameData, setFundNameData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const { /*fundNameOptions, */ mutualFundSymbolOptions } = useSelector(
    ({ report }) => ({
      // fundNameOptions: get(report, 'filerNames', {}),
      mutualFundSymbolOptions: get(report, 'symbols', {}),
    }),
    shallowEqual,
  );
  const fetchFilerNames = async (value) => {
    setFetching(true);
    const res = await getFilerNames({ keyword: value });
    if (res) {
      setFundNameData(res);
    }
    setFetching(false);
  };
  function getDisplayableQtr(qtrStr) {
    const qtrString = qtrStr.split('_');
    return qtrString[1] + '-' + qtrString[0];
  }
  const note = {
    TS:
      trendingStockType === 'ACTIVES'
        ? ' The Default Sort is based on the Current  volume'
        : 'The Default Sort is based on the Returns %  column ',
    SDY: 'The Default Sort is based on the Sec Reported date & time',
    SSL: 'The Default Sort is based on the SC 13D/G count',
    SDL: 'The Default Sort is based on the Sec Reported date & time',
    SAV: 'The Default Sort is based on the Approx. Trxn Value',
    DDTU: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DDTUV: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DDTD: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DDTDV: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    AID: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    ADMA: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    ADUD: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DTU: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DTD: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    AINB: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    AIU: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    AIO: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    TTNB: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    TTU: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    TTO: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DRMF: 'The Default Sort is based on the Shares value',
    AMFR: 'The Default Sort is based on the Shares value',
    DIL: 'The Default Sort is based on the Sec Reported date & time',
    DIA: 'The Default Sort is based on the Sec Reported date & time',
    DISS: 'The Default Sort is based on the Sec Insiders count',
    QTBS: 'The Default Sort is based on the Approx. Trxn Value',
    ITT: 'The Default Sort is based on the Market Value',
    IS: 'The Default Sort is based on the Market Value',
    SR: 'The Default Sort is based on the Sector column',
  };

  // const [value, setValue] = useState(searchText);

  const selectObjectList = (options: Array<{ name: string; value: string }>) =>
    options.map((p) => (
      <Option key={p.value} value={p.value}>
        {p.name}
      </Option>
    ));

  const getIndividualTop10DefaultValue = () => {
    let individualTop10 = cik ? topOptions.filter((d) => d.value === cik) : [];
    return individualTop10.length > 0
      ? individualTop10[0].name
      : 'RENAISSANCE TECHNOLOGIES LLC';
  };

  const getIndividualSpecialDefaultValue2 = () => {
    let individualSpecial = cik
      ? specialOptions.filter((d) => d.value === cik)
      : [];

    return individualSpecial.length > 0
      ? individualSpecial[0].name
      : 'ARMISTICE CAPITAL, LLC';
  };

  const onChange = (event) => {
    const { value } = event.target;
    // setValue(nextValue);
    debouncedSave(value);
  };

  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );
  const mandatoryFilters = NonClickAbleFilter(reportCode);
  const isFilterEmpty = Object.keys(filterOptions).filter((d) =>
    d === 'symbols' ||
    d === 'multiSector' ||
    d === 'excludeMultiSector' ||
    d === 'multiIndustry' ||
    d === 'multiStatus' ||
    d === 'multiMarketCap' ||
    d === 'institutions'
      ? filterOptions[d] && filterOptions[d].length === 0
        ? undefined
        : mandatoryFilters.includes(d)
        ? undefined
        : filterOptions[d]
      : mandatoryFilters.includes(d)
      ? undefined
      : filterOptions[d],
  );
  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filterOptions };
    if (name === 'symbols') {
      updatedFilters = {
        ...filterOptions,
        symbols: Array.isArray(updatedFilters.symbols)
          ? updatedFilters.symbols.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'institutions') {
      updatedFilters = {
        ...filterOptions,
        institutions: Array.isArray(updatedFilters.institutions)
          ? updatedFilters.institutions.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiSector') {
      updatedFilters = {
        ...filterOptions,
        multiSector: Array.isArray(updatedFilters.multiSector)
          ? updatedFilters.multiSector.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'excludeMultiSector') {
      updatedFilters = {
        ...filterOptions,
        excludeMultiSector: Array.isArray(updatedFilters.excludeMultiSector)
          ? updatedFilters.excludeMultiSector.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiIndustry') {
      updatedFilters = {
        ...filterOptions,
        multiIndustry: Array.isArray(updatedFilters.multiIndustry)
          ? updatedFilters.multiIndustry.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiStatus') {
      updatedFilters = {
        ...filterOptions,
        multiStatus: Array.isArray(updatedFilters.multiStatus)
          ? updatedFilters.multiStatus.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiMarketCap') {
      updatedFilters = {
        ...filterOptions,
        multiMarketCap: Array.isArray(updatedFilters.multiMarketCap)
          ? updatedFilters.multiMarketCap.filter((s) => s !== value)
          : undefined,
      };
    } else {
      delete updatedFilters[name];
    }
    onFilter(updatedFilters);
    setOpenFilterForm(false);
  };
  const handleReset = () => {
    onFilter({}, [], true);
  };
  console.log('filterOptions', filterOptions);
  return (
    <div className={styles.reportFilter}>
      <Row span={24} justify="space-between">
        {reportCode !== 'SR' && reportCode !== 'QTBS' && (
          <Col className={styles.vSearchBlock} id="reportSearchInputField">
            <Input
              placeholder="search..."
              onChange={onChange}
              //style={{ width: 300 }}
              // value={searchText}
              defaultValue={searchText}
              className={styles.VdailySearch}
              // enterButton
              allowClear
              // loading={isLoading}
            />
          </Col>
        )}
        <Col>
          <span className={styles.reportTitle2}>
            {getReportTitle(
              reportCode,
              get(filterOptions, 'symbols', 'RGA'),
              lastUpdatedTime,
              get(filterOptions, 'criterion', ''),
              get(filterOptions, 'startDate', ''),
              get(filterOptions, 'endDate', ''),
              selectedQuarter,
              get(filterOptions, 'status', 'INCREASED'),
            )}
            {reportCode === 'SR' && (
              <span
                className={styles.pieChart}
                onClick={() => setShowAllocation(true)}
              >
                <PieChart
                  height={100}
                  width={100}
                  radius={20}
                  dataKey={'allocationPercentage'}
                  data={
                    sectorAllocation && sectorAllocation.length
                      ? sectorAllocation
                      : []
                  }
                />
              </span>
            )}
          </span>
        </Col>

        <Col>
          <Row span={24} justify="end">
            {reportCode === 'ITT' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
                // id="reportQuarters"
              >
                <Select
                  placeholder="Individual Top-10"
                  defaultValue={getIndividualTop10DefaultValue()}
                  style={{ width: 300 }}
                  onChange={(value) => setCik(value)}
                  disabled={isLoading}
                >
                  {selectObjectList(topOptions)}
                </Select>
              </div>
            )}

            {reportCode === 'IS' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
                // id="reportQuarters"
              >
                <Select
                  placeholder="Individual Special"
                  defaultValue={getIndividualSpecialDefaultValue2()}
                  style={{ width: 300 }}
                  onChange={(value) => setCik(value)}
                  disabled={isLoading}
                >
                  {selectObjectList(specialOptions)}
                </Select>
              </div>
            )}
            {(reportCode === 'AIU' ||
              reportCode === 'TTU' ||
              reportCode === 'AID') && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label
                  style={{ color: green }}
                  className={styles.vlabelfull}
                >{`#Qtrs: `}</label>
                <Select
                  defaultValue={get(filterOptions, 'noOfQuarters', 2)}
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfQtrsChange(value)}
                  placeholder="Select No of Quarter"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {noOfQtrList.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {(reportCode === 'AIU' || reportCode === 'TTU') && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={'COUNT'}
                  style={{ width: 120 }}
                  onChange={(value) => onCriteriaChange(value)}
                  placeholder="Select Criteria"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {['COUNT', 'SHARES', 'BOTH'].map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {reportCode === 'DTU' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfWeeksList, 'Increased', [])}
                  defaultValue={get(filterOptions, 'noOfWeeks', 4)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfWeeksChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                  isweek={true}
                />
              )}
            {reportCode === 'DDTU' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfDaysList, 'Increased', [])}
                  defaultValue={get(filterOptions, 'noOfDays', 2)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfDaysChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                />
              )}
            {reportCode === 'DDTUV' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfDaysForVolumeList, 'Increased', [])}
                  defaultValue={get(filterOptions, 'noOfDays', 2)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfDaysChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                />
              )}
            {reportCode === 'DTD' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfWeeksList, 'Decreased', [])}
                  defaultValue={get(filterOptions, 'noOfWeeks', 4)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfWeeksChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                  isweek={true}
                />
              )}
            {reportCode === 'DDTD' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfDaysList, 'Decreased', [])}
                  defaultValue={get(filterOptions, 'noOfDays', 2)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfDaysChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                />
              )}
            {reportCode === 'DDTDV' &&
              get(filterOptions, 'symbols', []).length === 0 &&
              searchText.length === 0 && (
                <DaysDropDown
                  className={styles.reportOptionBlock}
                  options={get(noOfDaysForVolumeList, 'Decreased', [])}
                  defaultValue={get(filterOptions, 'noOfDays', 2)}
                  isLoading={isLoading}
                  onChange={(value) => onNoOfDaysChange(value)}
                  labelClassName={styles.vlabelfull}
                  selectClassName={styles.vSelectSearchnew}
                />
              )}
            {(reportCode === 'DIA' || reportCode === 'DIL') && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={get(filterOptions, 'type', 'All')}
                  style={{ width: 80 }}
                  onChange={(value) => onTypeChange(value)}
                  placeholder="Select No Of Weeks"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                  key={reportCode}
                >
                  {(typeOptions || []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {reportCode === 'SR' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={get(
                    filterOptions,
                    'category',
                    'All Institutions',
                  )}
                  style={{ width: 180 }}
                  onChange={(value) => onCategoryChange(value)}
                  placeholder="Select Category"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {(categoryOptions || []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {false && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={get(filterOptions, 'type', '1 Year')}
                  style={{ width: 110 }}
                  onChange={(value) => onDurationChange(value)}
                  placeholder="Select Duration"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {(durationOptions || []).map((option) => (
                    <Option key={option.name} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {reportCode === 'SAV' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={
                    filterOptions.status === 'DECREASED' ? 'SELL' : 'BUY'
                  }
                  style={{ width: 180 }}
                  onChange={(value) => onStatusTxnChange(value)}
                  placeholder="Select Category"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {(statusTxnOptions || []).map((option) => (
                    <Option key={option.name} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {reportCode === 'BFN' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  notFoundContent={
                    fetching ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  onSearch={fetchFilerNames}
                  onChange={(value) => onFilerNameChange(value)}
                  showSearch
                  placeholder="Fund"
                  size="large"
                >
                  {fundNameData.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            {reportCode === 'SSMF' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Select
                  defaultValue={get(filterOptions, 'symbols', 'RGA')}
                  style={{ width: 180 }}
                  onChange={(value) => onSymbolChange(value)}
                  placeholder="Select Symbol"
                  disabled={isLoading}
                  className={styles.vSelectSearchnew}
                >
                  {(mutualFundSymbolOptions || []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            {(reportType === 'quarterly' || reportCode === 'QTBS') &&
              reportCode !== 'SR' &&
              reportCode === 'QTBS' &&
              quartersOptions.length > 0 && (
                <div
                  className={styles.reportOptionBlock}
                  style={{ marginRight: 20 }}
                  id="reportQuarters"
                >
                  <Select
                    defaultValue={quartersOptions[0].name}
                    style={{ width: 160 }}
                    onChange={(value) => onQuarterChange(value)}
                    placeholder="Select Quarter"
                    disabled={isLoading}
                  >
                    {selectObjectList(quartersOptions)}
                  </Select>
                </div>
              )}
            {reportType === 'quarterly' && reportCode === 'DR' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
                id="reportQuarters"
              >
                <Select
                  defaultValue={filterOptions.category}
                  style={{ width: 160 }}
                  onChange={(value) => onCategoryChange(value)}
                  placeholder="Select Type"
                  disabled={isLoading}
                >
                  {selectObjectList([
                    { name: 'Quarterly', value: 'Quarterly' },
                    { name: 'Yearly', value: 'Yearly' },
                  ])}
                </Select>
              </div>
            )}
            {reportType === 'daily' && reportCode === 'TS' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
                id="reportQuarters"
              >
                Performance:
                <Select
                  defaultValue={filterOptions.category}
                  style={{ width: 100 }}
                  onChange={(value) => onCategoryChange(value)}
                  placeholder="Select Type"
                  disabled={isLoading}
                >
                  {selectObjectList([
                    { name: 'Day', value: 'Day' },
                    { name: 'Week', value: 'Week' },
                    { name: 'Month', value: 'Month' },
                    { name: '3 Months', value: '3Months' },
                    { name: '6 Months', value: '6Months' },
                    { name: 'YTD', value: 'YTD' },
                    { name: '1 Year', value: '1Year' },
                    { name: '5 Years', value: '5Years' },
                  ])}
                </Select>
              </div>
            )}

            {(reportCode === 'TS' ||
              reportCode === 'AID' ||
              reportCode === 'DDTDV' ||
              reportCode === 'DDTD' ||
              reportCode === 'DTU' ||
              reportCode === 'DTD' ||
              reportCode === 'DDTUV' ||
              reportCode === 'DDTU') && (
              <DOWJonesFilter
                className={styles.spBox}
                filterOptions={filterOptions}
                onDOWJONESChange={onDOWJONESChange}
                onNasdaqChange={onNasdaqChange}
                onSPChange={onSPChange}
              />
            )}
            {(reportCode === 'DDTUV' || reportCode === 'DDTDV') && (
              <div className={styles.spBox}>
                <Checkbox
                  checked={get(filterOptions, 'sortByVol', false)}
                  onChange={onSoryByVolChange}
                >
                  Sort By Volume
                </Checkbox>
              </div>
            )}
            {!(reportCode === 'QTBS') && (
              <Button
                icon={<FilterOutlined />}
                size={20}
                onClick={() => {
                  setOpenFilterForm(!openFilterForm);
                  setOpenColumnFilter(false);
                }}
                //style={{ marginRight: 20 }}
                className={styles.Vfilterbtn}
                disabled={isLoading}
                id="reportColumnFilters"
              >
                Filter/Sort
              </Button>
            )}
            <Button
              icon={<SettingOutlined />}
              size={20}
              onClick={() => {
                setOpenColumnFilter(!openColumnFilter);
                setOpenFilterForm(false);
              }}
              disabled={isLoading}
              id="reportColumnVisibility"
              className={styles.Visibilitybtn}
            >
              Column Visibility
            </Button>
          </Row>
        </Col>
      </Row>
      <div className={styles.titleContent}>
        <p className={styles.reportTitle}>
          {getReportTitle(
            reportCode,
            get(filterOptions, 'symbols', 'RGA'),
            lastUpdatedTime,
            get(filterOptions, 'criterion', ''),
            get(filterOptions, 'startDate', ''),
            get(filterOptions, 'endDate', ''),
            selectedQuarter,
            get(filterOptions, 'status', 'INCREASED'),
          )}
          {reportCode === 'SR' && (
            <span
              className={styles.pieChart}
              onClick={() => setShowAllocation(true)}
            >
              <PieChart
                height={100}
                width={100}
                radius={20}
                dataKey={'allocationPercentage'}
                data={
                  sectorAllocation && sectorAllocation.length
                    ? sectorAllocation
                    : []
                }
              />
            </span>
          )}
        </p>
      </div>
      <div>
        <p
          style={{
            color: 'var(--whitecolor)',
            fontSize: '12px',
            textAlign: 'center',
            marginLeft: reportCode === 'SR' ? '-100px' : '',
            marginTop: reportCode === 'SR' ? '-25px' : '',
          }}
        >
          <span style={{ color: 'red' }}>
            {note[reportCode]?.length ? '*' : ''}
          </span>{' '}
          {note[reportCode]}
          <br />
          <span
            hidden={
              (trendingStockType !== 'GAINERS' &&
                trendingStockType !== 'DECLINERS') ||
              reportCode !== 'TS'
            }
          >
            {' '}
            Note: Excluded Symbols Below $1 Current Price
          </span>
        </p>
      </div>
      {reportCode === 'SAV' && (
        <div
          style={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontSize: '15px',
              textAlign: 'center',
              color: 'var(--whitecolor)',
            }}
          >
            ( Period :{' '}
            {moment(getCurrentSECDateRange().start).format('MMMM DD, YYYY')} to{' '}
            {moment(lastUpdatedTime)
              .tz('America/New_York')
              .format('MMMM DD, YYYY')}{' '}
            )
          </span>
        </div>
      )}
      <div className={styles.pickupRightBox}>
        {isFilterEmpty.length > 0 && (
          <FilteredValues
            filterOptions={filterOptions}
            handleClick={handleFilterValueClick}
            handleReset={handleReset}
            reportCode={reportCode}
          />
        )}
      </div>

      {openFilterForm && (
        <FilterForm
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          reportCode={reportCode}
          trendingStockType={trendingStockType}
          filterOptions={filterOptions}
          cik={cik}
          columns={tableColumns.filter(
            (col) => col.visible === true && col.value,
          )}
          sortDetail={sortDetail}
        />
      )}
      {reportCode === 'TS' && (
        <Tabs
          defaultActiveKey={trendingStockType}
          style={{ width: '100%', height: 40 }}
          onChange={(key) => setTrendingStockType(key)}
          centered
          id="trendingStockTab"
        >
          <TabPane tab="Gainers" key="GAINERS"></TabPane>
          <TabPane tab="Penny Gainers" key="PENNY_GAINERS"></TabPane>
          <TabPane tab="Decliners" key="DECLINERS"></TabPane>
          <TabPane tab="Penny Decliners" key="PENNY_DECLINERS"></TabPane>
          <TabPane tab="High Volume" key="ACTIVES"></TabPane>
          <TabPane tab="52 W High" key="FIFTY_TWO_WEEK_HIGH"></TabPane>
          <TabPane tab="52 W Low" key="FIFTY_TWO_WEEK_LOW"></TabPane>
        </Tabs>
      )}
      {openColumnFilter && (
        <ColumnVisibility
          visible={openColumnFilter}
          onCancel={() => setOpenColumnFilter(false)}
          tableColumns={tableColumns}
          onColumnFilter={onColumnFilter}
        />
      )}
      {showAllocation && (
        <DraggableModal
          visible={showAllocation}
          title={
            <p style={{ margin: 0, textAlign: 'center' }}>{`${get(
              filterOptions,
              'category',
              'All Institutions',
            )} Sector Wise Allocation for  ${selectedQuarter.replace(
              '_',
              '-',
            )} Quarter`}</p>
          }
          width={1100}
          onCancel={() => setShowAllocation(false)}
          footer={null}
        >
          <PieChart
            height={500}
            width={1050}
            radius={150}
            data={
              sectorAllocation && sectorAllocation.length
                ? sectorAllocation
                : []
            }
            dataKey={'allocationPercentage'}
          />
        </DraggableModal>
      )}
    </div>
  );
};
export default ReportFilter;
