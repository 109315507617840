import React from 'react';
// import ReactGA from "react-ga";
// import { Auth } from 'aws-amplify';
// import { get } from 'lodash';

import { createBrowserHistory } from 'history';
import { BrowserRouter, Switch } from 'react-router-dom';

import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';
import NonSubscribedLayout from './layouts/NonSubscribedLayout';
import * as Views from './views';
import { XMLfile } from './views';

interface Props {
  authState?: string;
  rga?: any;
}

const history = createBrowserHistory();
const Routes: React.FC<Props> = ({ authState, rga, ...props }) => {
  return (
    <BrowserRouter history={history}>
      <Switch>
        <AuthLayout
          exact
          path="/"
          component={Views.Home}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          {...props}
        />
        <AuthLayout
          exact
          path="/free-trial"
          component={Views.FreeTrial}
          authState={authState}
          history={history}
          showHeader={true}
          showFooter={true}
          title={'Free Trial'}
          {...props}
        />
        <AuthLayout
          exact
          path="/sign-up"
          component={Views.SignUp}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Sign-Up'}
          {...props}
        />
        <AuthLayout
          exact
          path="/login"
          component={Views.Login}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Login'}
          {...props}
        />
        <AuthLayout
          exact
          path="/promo"
          component={Views.PromoCode}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Promo Code'}
          {...props}
        />
        <AuthLayout
          path="/verify/:username/:code"
          component={Views.SignUp}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Sign-Up'}
          {...props}
        />
        <AuthLayout
          path="/forgot-password/:username/:code"
          component={Views.ForgotPassword}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Forgot Password'}
          {...props}
        />
        <AuthLayout
          path="/forgot-password"
          component={Views.ForgotPassword}
          authState={authState}
          history={history}
          showHeader={false}
          showFooter={false}
          title={'Forgot Password'}
          {...props}
        />
        <AuthLayout
          path="/contact-us"
          component={Views.ContactAndFAQ}
          authState={authState}
          history={history}
          title={'Contact US'}
          {...props}
        />
        <AuthLayout
          path="/pricing"
          component={Views.Pricing}
          authState={authState}
          history={history}
          title={'Pricing'}
          {...props}
        />
        <AuthLayout
          path="/privacy-policy"
          component={Views.PrivacyPolicy}
          authState={authState}
          history={history}
          title={'Privacy Policy'}
          {...props}
        />
        <AuthLayout
          path="/tnc"
          component={Views.TermsAndConditions}
          authState={authState}
          history={history}
          title={'Terms & Conditions'}
          {...props}
        />
        <AuthLayout
          path="/founder-story"
          component={Views.FounderStory}
          authState={authState}
          history={history}
          title={'Founder Story'}
          {...props}
        />
        <AuthLayout
          path="/donate"
          component={Views.Donate}
          authState={authState}
          history={history}
          title={'Donate'}
          {...props}
        />
        <AuthLayout
          path="/reports/isr"
          component={Views.OpenReport}
          authState={authState}
          history={history}
          title={'isr'}
          {...props}
        />
        <AuthLayout
          path="/daytrend"
          component={Views.DayTrend}
          authState={authState}
          history={history}
          title={'day trend'}
          {...props}
        />
        <AuthLayout
          path="/blog"
          component={Views.Blog}
          authState={authState}
          history={history}
          title={'Blog'}
          {...props}
        />
        <AuthLayout
          path="/tools"
          component={Views.Tools}
          authState={authState}
          history={history}
          title={'tools'}
          {...props}
        />
        <AuthLayout
          path="/unsubscribe"
          component={Views.UnSubscribe}
          authState={authState}
          history={history}
          title={'Unsubscribe'}
          {...props}
        />
        <AuthLayout
          path="/blogPage"
          component={Views.BlogPage}
          authState={authState}
          history={history}
          title={'Blog Page'}
          {...props}
        />
        <AuthLayout
          path="/blogSubPage"
          component={Views.BlogSubPage}
          authState={authState}
          history={history}
          title={'Blog Page'}
          {...props}
        />
        <AuthLayout
          path="/whoWeAre"
          component={Views.WhoWeAre}
          authState={authState}
          history={history}
          title={'WhoWeAre'}
          {...props}
        />
        <AuthLayout
          path="/tools"
          component={Views.Tools}
          authState={authState}
          history={history}
          title={'tools'}
          {...props}
        />
        <AuthLayout
          path="/unsubscribe"
          component={Views.UnSubscribe}
          authState={authState}
          history={history}
          title={'Unsubscribe'}
          {...props}
        />
        <AuthLayout
          path="/blogPage"
          component={Views.BlogPage}
          authState={authState}
          history={history}
          title={'Blog Page'}
          {...props}
        />
        <AuthLayout
          path="/blogSubPage"
          component={Views.BlogSubPage}
          authState={authState}
          history={history}
          title={'Blog Page'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university"
          component={Views.University}
          authState={authState}
          history={history}
          title={'University'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university/:type"
          component={Views.UniversityType}
          authState={authState}
          history={history}
          title={'University'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university/:type/telegram-groups"
          component={Views.UniversityTelegramGroups}
          authState={authState}
          history={history}
          title={'Telegram Groups'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university/:type/expert-talk"
          component={Views.UniversityExperts}
          authState={authState}
          history={history}
          title={'Expert Talk'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university/:type/success-story"
          component={Views.UniversitySuccessStories}
          authState={authState}
          history={history}
          title={'Success Story'}
          {...props}
        />
        <AuthLayout
          exact
          path="/university/:type/report-videos"
          component={Views.VideosOnReports}
          authState={authState}
          history={history}
          title={'Report Videos'}
          {...props}
        />

        <AuthLayout
          exact
          path="/university/:type/popular-stock/:stockCategory?"
          component={Views.PopularStocks}
          authState={authState}
          history={history}
          title={'Popular Stock'}
          {...props}
        />

        <AuthLayout
          path="/faq"
          component={Views.FAQ}
          authState={authState}
          history={history}
          title={'FAQ'}
          {...props}
        />
        <DefaultLayout
          path="/alerts"
          component={Views.Alert}
          authState={authState}
          history={history}
          title={'Alerts'}
          {...props}
        />

        <DefaultLayout
          path="/setting"
          component={Views.Setting}
          authState={authState}
          history={history}
          title={'Setting'}
          {...props}
        />
        <DefaultLayout
          path="/sitemap.xml"
          component={XMLfile}
          authState={authState}
          history={history}
          title={'siteMap'}
          {...props}
        />

        <NonSubscribedLayout
          path="/membership"
          component={Views.Membership}
          authState={authState}
          history={history}
          title={'Membership'}
          {...props}
        />
        <DefaultLayout
          path="/community"
          component={Views.Community}
          authState={authState}
          history={history}
          title={'Community'}
          {...props}
        />
        <DefaultLayout
          path="/earningsCalender"
          component={Views.EarningsCalender}
          authState={authState}
          history={history}
          title={'EarningsCalender'}
          {...props}
        />
        <DefaultLayout
          path="/earningsCalenderByDate"
          component={Views.EarningsCalender}
          authState={authState}
          history={history}
          title={'EarningsCalenderByDate'}
          {...props}
        />
        <DefaultLayout
          path="/earningsCalenderBySymbol"
          component={Views.EarningsCalenderBySymbol}
          authState={authState}
          history={history}
          title={'EarningsCalenderBySymbol'}
          {...props}
        />

        <DefaultLayout
          path="/guidelines"
          component={Views.CommunityGuidelines}
          authState={authState}
          history={history}
          title={'Guidelines'}
          {...props}
        />

        <DefaultLayout
          path="/our/picks/:type/"
          component={Views.Picks}
          authState={authState}
          history={history}
          title={'Our Picks'}
          {...props}
        />
        <DefaultLayout
          path="/trending/:type/:subType"
          component={Views.TrendingData}
          authState={authState}
          history={history}
          title={'Trending Data'}
          {...props}
        />
        <DefaultLayout
          path="/GamificationByState"
          component={Views.GamificationByState}
          authState={authState}
          history={history}
          title={'Gamification By State'}
          {...props}
        />
        <DefaultLayout
          path="/GamificationByCounty"
          component={Views.GamificationByCounty}
          authState={authState}
          history={history}
          title={'Gamification By County'}
          {...props}
        />
        <DefaultLayout
          path="/portfolio/:mainTab/:type/:portfolioQueryData?"
          component={Views.Portfolio}
          authState={authState}
          history={history}
          title={'Portfolio'}
          // showFooter={false}
          {...props}
        />
        <DefaultLayout
          path="/stock/:symbol/:type"
          component={Views.StockPage}
          authState={authState}
          history={history}
          title={'Stocks'}
          {...props}
        />
        <DefaultLayout
          path="/reports/:reportQueryData?"
          component={Views.Reports}
          authState={authState}
          history={history}
          title={'Reports'}
          {...props}
        />
        <DefaultLayout
          path="/search/report/:searchQueryData"
          component={Views.SearchReport}
          authState={authState}
          history={history}
          title={'Search Report'}
          {...props}
        />
        <DefaultLayout
          path="/search"
          component={Views.Search}
          authState={authState}
          history={history}
          title={'Search'}
          {...props}
        />
        <DefaultLayout
          path="/dashboard"
          component={Views.Dashboard}
          authState={authState}
          history={history}
          title={'Dashboard'}
          {...props}
        />
        <DefaultLayout component={Views.PageNotFound} {...props} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
