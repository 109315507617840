import React, { useEffect, useState } from 'react';

// import { useStripe } from '@stripe/react-stripe-js';
import { Form, Input, Col, Select, Row, Button, Divider, Checkbox } from 'antd';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isReferralBoolean, makeMonthlyStripeTransaction } from '../../graphql';
import { red } from '../../utils';
import { countryCodes } from '../../utils';
import CardElementInput from '../StripeElement';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  onFinish: Function;
  isLoading: boolean;
  error: string;
  setError: Function;
  plan: string;
  btnText: string;
  isNewUser: Boolean;
}

const PaymentForm: React.FC<Props> = ({
  onFinish,
  isLoading,
  error,
  setError,
  plan,
  btnText,
  // isNewUser,
}) => {
  useEffect(() => {
    getReferralBoolean().then((res) => {
      console.log('res', res.referral_to_claimed);
      setIsReferral(!res.referral_to_claimed);
      // const bal = res.creditBalance;
      const bal = res.creditBalance;
      setBalance(bal !== 0 ? bal / 100 : 0);
    });
  }, []);
  const price = plan === 'yearly' ? 99.99 : 9.99;
  const [tncAccepted, setTncAccepted] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState('TSREFERRAL');
  const [balance, setBalance] = useState(0);
  const [couponError, setCouponError] = useState('');
  const [isReferral, setIsReferral] = useState(false);

  const { personalData, customer } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
      customer: get(user, 'profile.stripeCustomer', {}),
    }),
    shallowEqual,
  );
  const customerId = get(customer, 'stripe_customer_id', '');
  const email = get(personalData, 'email', '');
  const hiddenVar = balance === 0 || `$${price}` !== `$9.99`;
  // const stripe = useStripe();
  const getReferralBoolean = async () => {
    return await isReferralBoolean({ email: email });
  };
  const makeStripeTransaction = async () => {
    return await makeMonthlyStripeTransaction({ customer: customerId });
  };
  const onSubmit = (values) => {
    if (isReferral && `$${price}` === `$99.99`) {
      values.promoCode = 'TSREFERRAL';
    }
    if (price + balance <= 0 && `$${price}` === `$9.99`) {
      values.promoCode = 'TESTREFERRAL';
      makeStripeTransaction().then((res) => {
        console.log('res', res.txnSuccess);
      });
    }
    if (tncAccepted) {
      setError('');
      onFinish(values);
    } else {
      setError('Please accept the Terms and conditions');
    }
  };
  // const monthlyStripeTransaction = async () => {
  //   const txn = await stripe.customers.createBalanceTransaction(customerId, {
  //     amount: -balance * 100,
  //     currency: 'usd',
  //   });
  //   console.log('txn', txn);
  // };
  const onCheckboxChange = (e) => {
    setTncAccepted(e.target.checked);
    setError('');
  };
  const onCouponChange = (e) => {
    setCouponCode('TSREFERRAL');
    setCouponApplied(false);
    setCouponError('');
  };
  const applyCouponCode = () => {
    if (couponCode === 'TSREFERRAL') {
      setCouponApplied(true);
      setCouponError('');
    } else {
      setCouponApplied(false);
      setCouponError('Please enter a valid coupon code.');
    }
  };
  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      className={styles.paymentFormContainer}
      name="membership-form"
      onValuesChange={() => setError('')}
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 16 }}
      initialValues={{
        country: countryCodes.find((country) => country.code === 'US').name,
        tnc: false,
      }}
    >
      <Row span={24}>
        <Col span={24}>
          <Form.Item
            name="cardHolderName"
            // label="Name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder="Name on Card" className={styles.inputForm} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="cardNumber">
            <CardElementInput />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="country"
            // label="Country"
            rules={[{ required: true, message: 'Country is required' }]}
          >
            <Select
              placeholder="Select Country"
              className={styles.selectForm}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              size="middle"
            >
              {countryCodes.map((country) => (
                <Option key={country.code} value={country.name}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="address"
            //label="Address"
            rules={[{ required: true, message: 'Address is required' }]}
          >
            <Input placeholder="Address" className={styles.inputForm} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="state"
            style={{ paddingRight: 10 }}
            //label="State"
            rules={[{ required: true, message: 'State is required' }]}
          >
            <Input placeholder="State" className={styles.inputForm} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="city"
            style={{ paddingLeft: 10 }}
            // label="City"
            rules={[{ required: true, message: 'City is required' }]}
          >
            <Input placeholder="City" className={styles.inputForm} />
          </Form.Item>
        </Col>
        <Divider
          style={{
            borderTop: '1.1px solid #CCCCD6',
            margin: '5px 0 15px',
          }}
        />
        {price && (
          <Col span={24}>
            <Form.Item>
              <Row>
                <Col span={18}>
                  <p
                    style={{ textAlign: 'left', marginBottom: 10 }}
                    className={styles.totalBilledText}
                  >
                    Total Billed
                  </p>
                </Col>
                <Col span={6}>
                  <p
                    style={{ textAlign: 'right', marginBottom: 0 }}
                    className={styles.priceText}
                  >{`$${price}`}</p>
                </Col>

                {couponApplied && isReferral && `$${price}` === '$99.99' ? (
                  <>
                    <Col span={18}>
                      <p
                        style={{ textAlign: 'left', marginBottom: 0 }}
                        className={styles.codeApplyText}
                      >
                        Coupon code applied
                      </p>
                    </Col>
                    <Col span={6}>
                      <p
                        style={{ textAlign: 'right', marginBottom: 0 }}
                        className={styles.priceText2}
                      >{`-$9.99`}</p>
                    </Col>
                    <Col span={18} hidden={balance === 0}>
                      <p
                        style={{ textAlign: 'left', marginBottom: 0 }}
                        className={styles.codeApplyText}
                      >
                        TS Credit Balance
                      </p>
                    </Col>
                    <Col span={6} hidden={balance === 0}>
                      <p
                        style={{ textAlign: 'right', marginBottom: 0 }}
                        className={styles.priceText2}
                      >{`$${balance}`}</p>
                    </Col>
                    {/*{`$${price}` !== '$99.99' ? (*/}
                    {/*  <>*/}
                    {/*    <Col span={18}>*/}
                    {/*      <p*/}
                    {/*        style={{ textAlign: 'left', marginBottom: 0 }}*/}
                    {/*        className={styles.codeApplyText}*/}
                    {/*      >*/}
                    {/*        convenience Charges*/}
                    {/*      </p>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*      <p*/}
                    {/*        style={{ textAlign: 'right', marginBottom: 0 }}*/}
                    {/*        className={styles.priceText2}*/}
                    {/*      >{`$0.50`}</p>*/}
                    {/*    </Col>*/}
                    {/*  </>*/}
                    {/*) : (*/}
                    {/*  ''*/}
                    {/*)}*/}
                    <Col span={18}>
                      <p
                        style={{ textAlign: 'left', marginTop: 10 }}
                        className={styles.totalBilledText}
                      >
                        Amount you pay
                      </p>
                    </Col>
                    <Col span={6}>
                      <p
                        style={{
                          textAlign: 'right',
                          marginBottom: 0,
                          fontSize: 28,
                        }}
                        className={styles.priceText}
                      >
                        {`$${
                          price -
                          (isReferral ? 9.99 : 0) +
                          (`$${price}` === `$9.99` ? 9.99 : balance)
                        }`}
                      </p>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={18} hidden={balance === 0}>
                      <p
                        style={{ textAlign: 'left', marginBottom: 0 }}
                        className={styles.codeApplyText}
                      >
                        TS Credit Balance
                      </p>
                    </Col>
                    <Col span={6} hidden={balance === 0}>
                      <p
                        style={{ textAlign: 'right', marginBottom: 0 }}
                        className={styles.priceText2}
                      >{`${`$${price}` !== `$9.99` ? balance : `-$9.99`}`}</p>
                    </Col>
                    <Col span={18} hidden={hiddenVar}>
                      <p
                        style={{ textAlign: 'left', marginBottom: 0 }}
                        className={styles.codeApplyText}
                      >
                        convenience Charges
                      </p>
                    </Col>
                    <Col span={6} hidden={hiddenVar}>
                      <p
                        style={{ textAlign: 'right', marginBottom: 0 }}
                        className={styles.priceText2}
                      >{`$0.50`}</p>
                    </Col>
                    <Col span={18} hidden={balance === 0}>
                      <p
                        style={{ textAlign: 'left', marginTop: 10 }}
                        className={styles.totalBilledText}
                      >
                        Amount you pay
                      </p>
                    </Col>
                    <Col span={6} hidden={balance === 0}>
                      <p
                        style={{
                          textAlign: 'right',
                          marginBottom: 0,
                          fontSize: 28,
                        }}
                        className={styles.priceText}
                      >
                        {`$${
                          Math.round(
                            (price -
                              (isReferral ? 9.99 : 0) +
                              (`$${price}` === `$9.99` && balance <= -9.99
                                ? isReferral
                                  ? 0.5
                                  : -9.49
                                : balance)) *
                              100,
                          ) / 100
                        }`}
                      </p>
                    </Col>
                  </>
                )}
              </Row>
            </Form.Item>
          </Col>
        )}
        {price && isReferral && `$${price}` === '$99.99' ? (
          <Col span={24}>
            <Form.Item
              name="promoCode"
              // style={{ paddingLeft: 10 }}
              /// label="Enter your Coupon Code:"
              // rules={[{ required: true, message: 'City is required' }]}
            >
              <Row
                span={24}
                style={{
                  alignItems: 'center',
                }}
              >
                <Col span={10}>
                  <p className={styles.labelText}>Enter your Coupon Code:</p>
                </Col>
                <Col span={8}>
                  <Input
                    // placeholder="Enter your Coupon Code"
                    defaultValue={'TSREFERRAL'}
                    className={styles.inputForm}
                    onChange={onCouponChange}
                  />
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={applyCouponCode}
                    className={styles.applyBtnBox}
                  >
                    Applied
                  </Button>
                </Col>
                {couponError && (
                  <p style={{ color: red, marginBottom: '15px' }}>
                    {couponError}
                  </p>
                )}
              </Row>
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
        <Col span={24}>
          <Form.Item>
            <Checkbox
              className={styles.tncText}
              checked={tncAccepted}
              onChange={onCheckboxChange}
            >
              I accept to the{' '}
            </Checkbox>
            <Link
              to="/tnc"
              // onClick={() => setOpenTnc(true)}
              className={styles.tnc}
            >
              Terms and Conditions
            </Link>
          </Form.Item>
        </Col>

        {error && <p style={{ color: red, marginBottom: '15px' }}>{error}</p>}
        <Col span={24}>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              loading={isLoading}
              htmlType="submit"
              className={styles.formBtnBox}
            >
              {btnText}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentForm;
