import React, { useState } from 'react';

import { Tabs, Button } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

// import { Footer } from '../../../components';
import { green } from '../../../utils';
import HealthCheck from '../HealthCheck';
// import { PortfolioSnapShotType } from '../portfolio.types';
// import PortfolioSidebar from './PortfolioSidebar';
// import PortfolioSnapshot from './PortfolioSnapshot';
import Performance from '../performance';
import PortfolioMovement from '../PortfolioMovement';
import StocksTable from '../StocksTable';
import AddStock from './AddStock';
import RealizedGain from './RealizedGain';
import styles from './style.module.scss';
import TransactionHistory from './TransactionHistory';

const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  history: Object;
  portfolioType: any;
  isLoading: boolean;
  setFetchPortfolioData: Function;
  fetchPortfolioData: any;
  match: Object;
}

const VirtualPortfolioPage: React.FC<Props> = ({
  history,
  portfolioType,
  isLoading,
  setFetchPortfolioData,
  fetchPortfolioData,
  match,
}) => {
  const { portfolioMovementData, portfolioData, portfolioSnapshot, wallet } =
    useSelector(
      ({ portfolio, user }) => ({
        portfolioMovementData: get(portfolio, `movement.${portfolioType}`, []),
        portfolioData: get(portfolio, `data.virtual`, []),
        portfolioSnapshot: get(portfolio, 'snapshot', {}),
        wallet: get(user, 'wallet', {}),
      }),
      shallowEqual,
    );
  const [openAddStock, setOpenAddStock] = useState(false);

  const portfolioQueryData = queryString.parse(
    get(match, 'params.portfolioQueryData', {}),
  );
  const portfolioName = get(portfolioQueryData, 'name', '');
  const marketValue = get(wallet, `${portfolioType}.currentValue`, 0);
  const investedValue = get(wallet, `${portfolioType}.investedValue`, 0);
  const yesterdayValue = get(wallet, `${portfolioType}.yd_value`, 0);

  const summary = get(portfolioData, `data`, []).filter(
    (i) => i.symbol === 'Total',
  );

  const handleTabChange = (key) => {
    const path =
      `/portfolio/Portfolio/${portfolioType}/` +
      queryString.stringify(
        {
          type: key,
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
  };
  // const AvailableFund = get(wallet, `${portfolioType}.remainingBalance`, 0);
  let portfolioSnapshotData = get(
    portfolioSnapshot,
    `${portfolioType}.symbol`,
    [],
  );

  return (
    <div>
      <div className={styles.PortfolioPageBox}>
        {isLoading && (
          <span className={styles.spinnerStyle}>
            <BounceLoader color={green} loading={true} size={50} />
          </span>
        )}

        {get(portfolioData, 'data', []).length > 0 ? (
          <PortfolioMovement
            portfolioSnapshotData={portfolioSnapshotData}
            marketValue={marketValue}
            investedValue={investedValue}
            yesterdayValue={yesterdayValue}
            summary={summary}
            portfolioMovementData={portfolioMovementData}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 300,
            }}
          >
            <Button
              size="large"
              onClick={() => {
                setOpenAddStock(true);
              }}
              disabled={isLoading}
            >
              Add
            </Button>
          </div>
        )}

        <Tabs
          defaultActiveKey={get(portfolioQueryData, 'type', 'stock')}
          className={styles.vCenterTabsNew}
          centered
          onChange={handleTabChange}
          // id="vTabs1"
        >
          <TabPane tab="Stocks" key="stock">
            <div>
              <p
                style={{
                  color: 'var(--whitecolor)',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                <span style={{ color: 'red' }}>*</span>The Default Sort is based
                on the Todays returns column
              </p>
            </div>
            <StocksTable
              history={history}
              portfolioType={portfolioType}
              portfolioData={portfolioData}
              portfolioName={portfolioName}
              isLoading={isLoading}
              setFetchPortfolioData={setFetchPortfolioData}
              setOpenAddStock={setOpenAddStock}
            />
          </TabPane>
          <TabPane tab="Health Check" key="health_check">
            <HealthCheck
              history={history}
              portfolioType={portfolioType}
              fetchData={fetchPortfolioData}
              portfolioQueryData={portfolioQueryData}
            />
          </TabPane>

          <TabPane tab="Transaction History" key="transaction_history">
            <TransactionHistory
              portfolioData={get(portfolioData, `data`, [])}
            />
          </TabPane>
          <TabPane tab="Realized Gain/Loss" key="realized_gain_loss">
            <RealizedGain
              portfolioData={get(portfolioData, `data`, [])}
              walletBalance={get(wallet, 'remainingBalance', 0)}
            />
          </TabPane>
          <TabPane tab="Performance Report" key="performance_report">
            <div>
              <p
                style={{
                  color: 'var(--whitecolor)',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                <span style={{ color: 'red' }}>*</span>The Default Sort is based
                on the Total PF returns % column
              </p>
            </div>
            <Performance history={history}></Performance>
          </TabPane>
        </Tabs>
        {openAddStock && (
          <AddStock
            visible={openAddStock}
            handleCancel={() => setOpenAddStock(false)}
            history={history}
            portfolioType={portfolioType}
          />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default VirtualPortfolioPage;
