import React, { useState, useEffect } from 'react';

import { Form, Select, Row, Col, Button } from 'antd';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  esSearchCompany,
  esSearchInstitution,
  getIIRQuarters,
} from '../../graphql';
import { setIIRQuarters } from '../../redux/reducers/report';
import { green, criterionOptions } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
  prevPage: string;
}
const InstitutionSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [criterion, setCriterion] = useState(criterionOptions[0]);
  const [symbolData, setSymbolData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [institutionsData, setInstitutionsData] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [loadingCik, setLoadingCik] = useState<boolean>(false);

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (
      reportSubType === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);

  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };
  const onFinish = async (values) => {
    const { symbol, institution } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    const ciks = [];
    const names = [];
    // eslint-disable-next-line array-callback-return
    (selectedInstitutions || []).map((inst) => {
      const value = inst.split(',');
      ciks.push(value[0]);
      names.push(value[1]);
    });
    if (reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT') {
      setLoading(true);
      getIIRQuarters({
        context: reportSubType,
        ciks: [institution.split(',')[0]],
      }).then((res) => {
        dispatch(setIIRQuarters(institution.split(',')[0], res));
        filters.institutions = [institution.split(',')[0]];
        filters.institutionsName = [institution.split(',')[1]];
        setLoading(false);
        history.push({
          pathname:
            `/search/report/` +
            queryString.stringify(filters, {
              skipNull: true,
            }),
          state: { prevPage: 'search' },
        });
      });
    } else {
      switch (reportSubType) {
        // case 'INDIVIDUAL_INSTITUTION_REPORT':
        //   filters.institutions = [institution.split(',')[0]];
        //   filters.institutionsName = [institution.split(',')[1]];
        //   break;
        case 'COMPARE_INSTITUTIONS':
          filters.institutions = ciks;
          filters.institutionsName = names;
          break;
        case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
          filters.symbols = symbol;
          filters.institutions = ciks;
          filters.institutionsName = names;
          break;
        case 'INCREASED':
        case 'NO_CHANGE':
        case 'DECREASED':
        case 'NEWLY_BOUGHT':
        case 'SOLD_OFF':
          filters.criterion = criterion.toLowerCase();
          break;
        case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
          break;
        default:
          break;
      }
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  };

  // const changeInstitutionSearchType = (type) => {
  //   setSearchReportType(type);
  // };

  const changeCriterion = (type) => {
    setCriterion(type);
  };

  const handleSelect = (value) => {
    const ins = selectedInstitutions;
    ins.push(value.key);
    setSelectedInstitutions(ins);
    setInstitutionsData([]);
  };

  const handleDeSelect = (value) => {
    const ins = selectedInstitutions.filter((i) => i !== value.key);
    setSelectedInstitutions(ins);
  };

  return (
    <Form
      name="institution-search-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      size="small"
      initialValues={{
        criterion: criterionOptions[0],
      }}
    >
      <Row span={24}>
        {(reportSubType === 'COMPARE_INSTITUTIONS' ||
          reportSubType === 'VERIFY_INSTITUTIONS_VS_SYMBOLS') && (
          <Col span={24}>
            <Form.Item
              name="institutions"
              label="Enter Institution/ Insiders"
              style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Institution is required`,
                },
              ]}
            >
              <Select
                mode={'multiple'}
                placeholder="Enter Here"
                notFoundContent={
                  loadingCik ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                filterOption={false}
                onSearch={fetchInstitution}
                size="large"
                showSearch={true}
                allowClear={true}
                showArrow={false}
                onSelect={(value, key) => handleSelect(key)}
                onDeselect={(value, key) => handleDeSelect(key)}
              >
                {institutionsData.map((institution) => (
                  <Option
                    key={institution.cik + ',' + institution.name}
                    value={institution.name}
                  >
                    {institution.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' && (
          <Col span={24}>
            <Form.Item
              name="institution"
              label="Enter Institution"
              style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Institution is required`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Enter Here"
                notFoundContent={
                  loadingCik ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                filterOption={false}
                onSearch={fetchInstitution}
                size="large"
              >
                {institutionsData.map((institution) => (
                  <Option
                    value={institution.cik + ',' + institution.name}
                    key={institution.cik}
                  >
                    {institution.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label="Search Symbols"
              style={{ marginTop: 20 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid symbols"
                mode="multiple"
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                filterOption={false}
                showSearch
                onSelect={() => setSymbolData([])}
                allowClear={true}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {(reportSubType === 'INCREASED' ||
          reportSubType === 'NO_CHANGE' ||
          reportSubType === 'NEWLY_BOUGHT' ||
          reportSubType === 'SOLD_OFF' ||
          reportSubType === 'DECREASED') && (
          <Col span={24}>
            <Form.Item
              name="criterion"
              label="Criterion"
              style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Criterion is required`,
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                onChange={(value) => changeCriterion(value)}
              >
                {criterionOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default InstitutionSearch;
