import React, { useEffect, useState } from 'react';

import { notification } from 'antd';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { resetPicks } from '../../redux/reducers/picks';
import { resetPortfolio } from '../../redux/reducers/portfolio';
import { resetReport } from '../../redux/reducers/report';
import { resetSearch } from '../../redux/reducers/search';
import { resetUser, setLoggedInUserInfo } from '../../redux/reducers/user';
import { errorHandler } from '../../utils';
import DashboardHeader from '../DashboardHeader';
import SubscriptionPageHeader from '../DashboardHeader/SubscriptionPageHeader';
import StaticHeader from './StaticHeader';
// var CryptoJS = require('crypto-js');

const {
  // REACT_APP_SECRET_KEY = 'thinksabiotest@chat123',
  REACT_APP_CHAT_BASE_URL,
} = process.env;

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
  nonSubscribed: booleann;
}

const menuItemStyle = {
  height: 60,
  border: 'none',
  margin: '15px 0px',
  fontWeight: 700,
  padding: '0 15px',
};
const menuLinkStyle = {
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
};

const PageHeader: React.FC<Props> = ({
  history,
  match,
  onStateChange,
  nonSubscribed,
  title,
  //loggedInUser,
}) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [url] = useState(`${REACT_APP_CHAT_BASE_URL}`);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect(),
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);

  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
  };

  const openNotificationWithIcon = (type: string, message: string) => {
    notification[type]({
      message: message,
      placement: 'bottomLeft',
      duration: 10,
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  useEffect(() => {
    // const { family_name, given_name } = loggedInUser;
    window.addEventListener('online', () =>
      openNotificationWithIcon('success', 'You are Online'),
    );
    window.addEventListener('offline', () =>
      openNotificationWithIcon('error', 'You are currently offline'),
    );
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        dispatch(
          setLoggedInUserInfo({
            ...get(res, 'attributes', {}),
            groups: get(
              res,
              'signInUserSession.accessToken.payload.cognito:groups',
              [],
            ),
          }),
        );

        setUser(get(res, 'attributes'));
      })
      .catch();
  }, [dispatch]);
  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        dispatch(resetUser());
        dispatch(resetPortfolio());
        dispatch(resetSearch());
        dispatch(resetPicks());
        dispatch(resetReport());
        history.push('/');
      })
      .catch(errorHandler);
  };

  return nonSubscribed ? (
    <SubscriptionPageHeader
      match={match}
      history={history}
      signOut={signOut}
      menuLinkStyle={menuLinkStyle}
    />
  ) : (title === 'Free Trial' ||
      title === 'Contact US' ||
      title === 'Donate' ||
      title === 'isr' ||
      title === 'Privacy Policy' ||
      title === 'WhoWeAre' ||
      title === 'Pricing' ||
      title === 'Terms & Conditions') &&
    !user ? (
    <StaticHeader
      match={match}
      history={history}
      onStateChange={onStateChange}
      menuItemStyle={menuItemStyle}
      menuLinkStyle={menuLinkStyle}
      scrollY={scrollY}
    />
  ) : (
    <DashboardHeader
      match={match}
      history={history}
      user={user}
      chatUrl={url}
      signOut={signOut}
      menuItemStyle={menuItemStyle}
      menuLinkStyle={menuLinkStyle}
      scrollY={scrollY}
    />
  );
};

export default PageHeader;
