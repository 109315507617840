import React, { useState } from 'react';

// import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, InputNumber, Tabs } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  esSearchCompany,
  //esSearchInstitution
} from '../../graphql';
import { green } from '../../utils';
// import { topOptions } from '../reports/Reports.constants';
import {
  standardFields,

  // advanceRangeFields
} from './Search.constant';
import styles from './style.module.scss';

const { Option } = Select;
const { TabPane } = Tabs;

interface FormType {
  criterion: string;
  minPossibleGrowth: string;
  maxPossibleGrowth: string;
  minFiveYrGrowth: string;
  minInstCount: string;
  minInstCountChange: string;
  minPrice: string;
  maxPrice: string;
  minMarketCap: string;
  minOneYrGrowth: string;
  minOwnership: string;
  minP52WkHigh: string;
  minP52WkLow: string;
  country: string;
  minPrevInstCount: string;
  minAum: string;
  maxAum: string;
  hedgeCount: string;
  maxMarketCap: string;
  minVolume: string;
  minYtd: string;
  maxYtd: string;
  instCountDoubled: string;
  instSharesDoubled: string;
  institutions: string;
  isSP: string;
  noOfQuarters: string;
  p52WCurrentlyPotential: string;
  p52WFuturePotential: string;
  sector: string;
  status: string;
  symbols: string;
  // maxAum: string;
  // maxPossibleGrowth: string;
  // maxFiveYrGrowth: string;
  maxInstCount: string;
  // maxInstCountChange: string;
  // maxLastSale: string;
  // maxMarketCap: string;
  // maxOneYrGrowth: string;
  // maxOwnership: string;
  // maxP52WkHigh: string;
  // maxP52WkLow: string;
  // maxPrevInstCount: string;
  // maxVolume: string;
  maxYtd: string;
  top10hedgeFunds: string;
  maxInstSharesChange: string;
  minInstSharesChange: string;
}
interface Props extends RouteComponentProps<any> {
  reportType: string;
  sectorOptions: Object;
}
const BuildYourOwnSearch: React.FC<Props> = ({ history, reportType }) => {
  const { sectorOptions } = useSelector(
    ({ report }) => ({
      sectorOptions: get(report, 'sectors', {}),
    }),
    shallowEqual,
  );
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  // const [showAdvanced, setShowAdvances] = useState<boolean>(false);
  // const [institutionsData, setInstitutionsData] = useState([]);
  // const [loadingCik, setLoadingCik] = useState<boolean>(false);
  // const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  // const [
  //   selectedTop10HedgeInstitutions,
  //   setSelectedTop10HedgeInstitutions,
  // ] = useState([]);
  const fetchSymbols = async (value: Object) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  // const fetchInstitution = async (value: Object) => {
  //   setLoadingCik(true);
  //   const res = await esSearchInstitution(value);
  //   setInstitutionsData(res);
  //   setLoadingCik(false);
  // };

  const onFinish = (values: FormType) => {
    const {
      criterion,
      minPossibleGrowth,
      minFiveYrGrowth,
      minInstCount,
      maxInstCount,
      minInstCountChange,
      minPrice,
      maxPrice,
      minOneYrGrowth,
      minOwnership,
      minP52WkHigh,
      minP52WkLow,
      minPrevInstCount,
      minAum,
      maxAum,
      hedgeCount,
      minMarketCap,
      maxMarketCap,
      minVolume,
      minYtd,
      maxYtd,
      instCountDoubled,
      instSharesDoubled,
      // institutions,
      isSP,
      noOfQuarters,
      p52WCurrentlyPotential,
      p52WFuturePotential,
      multiSector,
      status,
      symbols,
      // maxAum,
      maxPossibleGrowth,
      // maxFiveYrGrowth,
      // maxInstCount,
      // maxInstCountChange,
      // maxLastSale,
      // maxMarketCap,
      // maxOneYrGrowth,
      // maxOwnership,
      // maxP52WkHigh,
      // maxP52WkLow,
      // maxPrevInstCount,
      // maxVolume,
      // top10hedgeFunds,
      // maxInstSharesChange,
      minInstSharesChange,
    } = values;
    // const ciks = [];
    // const insNames = [];

    // // eslint-disable-next-line array-callback-return
    // (selectedInstitutions || []).map((inst) => {
    //   const value = inst.split(',');
    //   ciks.push(value[0]);
    //   insNames.push(value[1]);
    // });

    // const top10HedgeCIks = (selectedTop10HedgeInstitutions || []).map(
    //   (inst) => {
    //     const value = inst.split(',');
    //     top10HedgeCIks.push(value[0]);
    //     // insNames.push(value[1]);
    //   },
    // );
    const filters = {
      type: reportType.toLowerCase(),
      criterion: criterion,
      minPossibleGrowth: minPossibleGrowth,
      maxPossibleGrowth: maxPossibleGrowth,
      minFiveYrGrowth: minFiveYrGrowth,
      minInstCount: minInstCount,
      maxInstCount: maxInstCount,
      minInstCountChange: minInstCountChange,
      minPrice: minPrice,
      maxPrice: maxPrice,
      minOneYrGrowth: minOneYrGrowth,
      minOwnership: minOwnership,
      minP52WkHigh: minP52WkHigh,
      minP52WkLow: minP52WkLow,
      minPrevInstCount: minPrevInstCount,
      minAum: minAum,
      maxAum: maxAum,
      hedgeCount: hedgeCount,
      minMarketCap: minMarketCap,
      maxMarketCap: maxMarketCap,
      minVolume: minVolume,
      minYtd: minYtd,
      maxYtd: maxYtd,
      instCountDoubled: instCountDoubled,
      instSharesDoubled: instSharesDoubled,
      // institutions: ciks,
      // institutionsName: insNames,
      isSP: isSP,
      noOfQuarters: noOfQuarters,
      p52WCurrentlyPotential: p52WCurrentlyPotential,
      p52WFuturePotential: p52WFuturePotential,
      multiSector: multiSector,
      status: status,
      symbols: symbols,
      // maxAum: maxAum,
      // maxPossibleGrowth: maxPossibleGrowth,
      // maxFiveYrGrowth: maxFiveYrGrowth,
      // maxInstCount: maxInstCount,
      // maxInstCountChange: maxInstCountChange,
      // maxLastSale: maxLastSale,
      // maxMarketCap: maxMarketCap,
      // maxOneYrGrowth: maxOneYrGrowth,
      // maxOwnership: maxOwnership,
      // maxP52WkHigh: maxP52WkHigh,
      // maxP52WkLow: maxP52WkLow,
      // maxPrevInstCount: maxPrevInstCount,
      // maxVolume: maxVolume,
      // top10hedgeFunds: top10HedgeCIks,
      // maxInstSharesChange: maxInstSharesChange,
      minInstSharesChange: minInstSharesChange,
    };
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  // const handleSelect = (value) => {
  //   const ins = selectedInstitutions;
  //   ins.push(value.key);
  //   setSelectedInstitutions(ins);
  //   setInstitutionsData([]);
  // };

  // const handleDeSelect = (value) => {
  //   const ins = selectedInstitutions.filter((i) => i !== value.key);
  //   setSelectedInstitutions(ins);
  // };

  // const handleSelect2 = (value) => {
  //   const ins = selectedTop10HedgeInstitutions;
  //   ins.push(value.key);
  //   setSelectedTop10HedgeInstitutions(ins);
  // };

  // const handleDeSelect2 = (value) => {
  //   const ins = selectedTop10HedgeInstitutions.filter((i) => i !== value.key);
  //   setSelectedTop10HedgeInstitutions(ins);
  // };

  return (
    <Form
      name="sector-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Standard Filters" key="1">
          <Row span={24}>
            {standardFields.map((field, i) => {
              return (
                <Col span={field.size} key={i}>
                  {field.type === 'range' && (
                    <Form.Item
                      // label={field.label}
                      name={field.name}
                    >
                      <InputNumber
                        style={{ width: 'calc(100% - 10px)' }}
                        //style={{ width: '100%' }}
                        placeholder={field.label}
                        type="number"
                        size="large"
                      />
                    </Form.Item>
                  )}
                  {field.type === 'dropdown' && (
                    <Form.Item
                      name={field.name}
                      className={styles.vFormSearhNew}
                    >
                      <Select
                        showSearch
                        placeholder={field.label}
                        className={styles.vBuildSelect}
                        size="large"
                        style={{ width: 'calc(100% - 10px)' }}
                      >
                        {field.data.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {field.type === 'sectorDropdown' && (
                    <Form.Item
                      name={field.name}
                      className={styles.vFormSearhNew}
                    >
                      <Select
                        showSearch
                        placeholder={field.label}
                        size="large"
                        style={{ width: 'calc(100% - 10px)' }}
                      >
                        {sectorOptions.map((p, i) => (
                          <Option key={p.value + i} value={p.value}>
                            {p.name.toUpperCase()}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {field.label === 'Symbols' && (
                    <Form.Item
                      name="symbols"
                      //label={'Enter Symbols'}
                      className={styles.vFormSearhNew}
                    >
                      <Select
                        placeholder="Enter valid symbols"
                        mode={'multiple'}
                        notFoundContent={
                          fetching ? (
                            <span className={styles.spinnerStyle}>
                              <BounceLoader
                                color={green}
                                loading={true}
                                size={50}
                              />
                            </span>
                          ) : null
                        }
                        onSearch={fetchSymbols}
                        showSearch={true}
                        allowClear={true}
                        showArrow={false}
                        filterOption={false}
                        onSelect={() => setSymbolData([])}
                        size="large"
                        style={{ width: 'calc(100% - 10px)' }}
                      >
                        {symbolData.map((d) => (
                          <Option key={d.symbol} className={styles.optionStyle}>
                            <span className={styles.optionSymbol}>
                              {d.symbol}
                            </span>{' '}
                            {d.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              );
            })}
          </Row>
        </TabPane>
        {/* <TabPane tab="Advance Filters" key="2">
          <Row className={styles.vAdvanceFilterBlock}>
            {advanceRangeFields.map((field, i) => {
              return (
                <Col span={field.size} key={i}>
                  {field.type === 'range' && (
                    <Form.Item
                      // label={field.label}
                      name={field.name}
                      className={styles.vFormSearhNew}
                    >
                      <InputNumber
                        style={{ width: 'calc(100% - 10px)' }}
                        placeholder={field.label}
                        type="number"
                        size="large"
                      />
                    </Form.Item>
                  )}
                  {field.type === 'dropdown' && (
                    <Form.Item name={field.name}>
                      <Select
                        showSearch
                        placeholder={field.label}
                        className={styles.vBuildSelect}
                        size="large"
                      >
                        {field.data.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {field.label === 'Institution' && (
                    <Form.Item
                      name="institutions"
                      className={styles.vFormSearhNew}
                    >
                      <Select
                        style={{ width: 'calc(100% - 10px)' }}
                        mode="multiple"
                        showSearch
                        placeholder="Enter Institution"
                        notFoundContent={
                          loadingCik ? (
                            <span className={styles.spinnerStyle}>
                              <BounceLoader
                                color={green}
                                loading={true}
                                size={50}
                              />
                            </span>
                          ) : null
                        }
                        filterOption={false}
                        onSearch={fetchInstitution}
                        size="large"
                        onSelect={(value, key) => handleSelect(key)}
                        onDeselect={(value, key) => handleDeSelect(key)}
                      >
                        {institutionsData.map((institution) => (
                          <Option
                            key={institution.cik + ',' + institution.name}
                            value={institution.name}
                          >
                            {institution.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {field.label === 'Top 10 Hedge funds' && (
                    <Form.Item
                      name="top10hedgeFunds"
                      className={styles.vFormSearhNew}
                    >
                      <Select
                        showSearch
                        placeholder="Enter Top 10 Hedge funds"
                        // notFoundContent={
                        //   loadingCik ? (
                        //     <span className={styles.spinnerStyle}>
                        //       <BounceLoader color={green} loading={true} size={50} />
                        //     </span>
                        //   ) : null
                        // }
                        filterOption={false}
                        // onSearch={fetchInstitution}
                        size="large"
                        onSelect={(value, key) => handleSelect2(key)}
                        onDeselect={(value, key) => handleDeSelect2(key)}
                      >
                        {topOptions.map((institution) => (
                          <Option
                            key={institution.value + '-#-' + institution.name}
                            value={institution.name}
                          >
                            {institution.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {field.type === 'radio' && (
                    <Form.Item
                      name={field.name}
                      label={field.label}
                      className={styles.vFormSearhNew}
                    >
                      <Radio.Group>
                        <Radio value={true}>yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {field.type === 'objectDropdown' && (
                    <Form.Item name={field.name}>
                      <Select
                        showSearch
                        placeholder={field.label}
                        size="large"
                        style={{ width: 'calc(100% - 10px)' }}
                      >
                        {field.data.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              );
            })}
          </Row>
        </TabPane> */}
      </Tabs>

      <Row span={24} justify="start">
        <Col span={24}>
          <Form.Item colon={false} style={{ marginTop: 20 }}>
            <div className={styles.vAdvanceterSubmit}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BuildYourOwnSearch;
