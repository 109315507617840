import React from 'react';

import { SafetyCertificateOutlined } from '@ant-design/icons';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  Form,
  Input,
  Col,
  Select,
  Row,
  Button,
  Divider,
  InputNumber,
  Radio,
} from 'antd';

import { countryCodes, red } from '../../utils';
import CardElementInput from '../StripeElement';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  handleDonation: () => {};
  setError: () => {};
  isLoading: boolean;
  error: string;
  setIsLoading: () => {};
}

const TSDonateForm: React.FC<Props> = ({
  handleDonation,
  setError,
  isLoading,
  error,
  // heroes,
  setIsLoading,
}: Props) => {
  // console.log('hero:', heroes);
  const stripe = useStripe();
  const elements = useElements();

  const onFinish = async (values) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus',
    );
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setIsLoading(false);
    } else {
      const paymentMethodId = paymentMethod.id;
      const cardDetail = paymentMethod.card;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        // const invoiceId = localStorage.getItem('latestInvoiceId');
        // retryInvoiceWithNewPaymentMethod({
        //   customerId,
        //   paymentMethodId,
        //   invoiceId,
        //   priceId,
        // });
      } else {
        // Create the subscription
        handleDonation({
          paymentMethodId,
          cardDetail,
          values,
        });
      }
    }
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className={styles.paymentFormContainer}
      name="donation-form"
      onValuesChange={() => setError('')}
      initialValues={{
        country: countryCodes.find((country) => country.code === 'US').name,
        // tnc: false,
        anonymous: false,
        donationCause: ' ',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginTop: '0px',
        }}
      >
        <div style={{ width: '25%', marginLeft: '100px' }}>
          {/*<h5 style={{ marginBottom: '10px' }}>Donate </h5>*/}
          <br />
          <Row span={24}>
            <Col span={24}>
              <Form.Item
                name="donorName"
                label="Donor Name"
                rules={[{ required: true, message: 'Donor Name is required' }]}
              >
                <Input placeholder="Donor Name" className={styles.inputForm} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Donor Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please enter valid Email!',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  size="middle"
                  className={styles.inputForm}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="tsHeroName"
                label="To Whom You Want to Donate (TS Hero)"
                rules={[
                  { required: true, message: 'TS Hero Name is required' },
                ]}
              >
                <Select
                  placeholder="Select TS Hero"
                  className={styles.selectForm}
                  showSearch
                  size="middle"
                >
                  <Option key={'Venu'} value={'Venu'}>
                    {'Venu'}
                  </Option>
                  <Option key={'Dhanu'} value={'Dhanu'}>
                    {'Dhanu'}
                  </Option>
                  <Option
                    key={'Milllionarie in the making'}
                    value={'Milllionarie in the making'}
                  >
                    {'Milllionarie in the making'}
                  </Option>
                  <Option key={'Karthik'} value={'Karthik'}>
                    {'Karthik'}
                  </Option>
                  <Option key={'RGV'} value={'RGV'}>
                    {'RGV'}
                  </Option>
                  <Option key={'Infinity'} value={'Infinity'}>
                    {'Infinity'}
                  </Option>
                  <Option key={'Naga R'} value={'Naga R'}>
                    {'Naga R'}
                  </Option>
                  {/*{(heroes || []).map((option) => (*/}
                  {/*  <Option key={option.name} value={option.name}>*/}
                  {/*    {option.value}*/}
                  {/*  </Option>*/}
                  {/*))}*/}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid amount!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value < 100000000000) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          'Amount should be less than $100,000,000,000',
                        );
                      }
                    },
                  }),
                ]}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please enter valid amount!',
                //   },
                // ]}
                style={{
                  width: '100%',
                }}
              >
                {/* <InputNumber
              // type="number"
              // defaultValue={1000}
              min={0}
              className={styles.inputForm}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            /> */}
                <InputNumber
                  // type={'number'}
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  // onInput={(value) =>
                  //   `$ ${value}`.toLowerCase().replace(/[a-z]/g, '')
                  // }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  //className={styles.inputForm}
                  size="middle"
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="donationCause"
                label="Donation Cause"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value) {
                        if (value.length < 160) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            'Donation Cause text should be less than 160 characters',
                          );
                        }
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Donation Cause"
                  className={styles.inputForm}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="anonymous"
                label="Donation Type"
                style={{ marginLeft: '10px' }}
              >
                <Radio.Group>
                  <Radio value={false}>Open</Radio>
                  <Radio value={true}>Anonymous</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ width: '25%', marginLeft: '100px', marginTop: '30px' }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="cardHolderName"
                // label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input
                  placeholder="Name on Card"
                  className={styles.inputForm}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="cardNumber"
                // label="Payment Detail"
                // rules={[
                //   { required: true, message: 'Payment Detail is required' },
                // ]}
              >
                <CardElementInput />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="country"
                // label="Country"
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <Select
                  placeholder="Select Country"
                  className={styles.selectForm}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="middle"
                >
                  {countryCodes.map((country) => (
                    <Option key={country.code} value={country.name}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                //label="Address"
                rules={[{ required: true, message: 'Address is required' }]}
              >
                <Input placeholder="Address" className={styles.inputForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="state"
                style={{ paddingRight: 10 }}
                //label="State"
                rules={[{ required: true, message: 'State is required' }]}
              >
                <Input placeholder="State" className={styles.inputForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="city"
                style={{ paddingLeft: 10, height: '50px' }}
                // label="City"
                rules={[{ required: true, message: 'City is required' }]}
              >
                <Input placeholder="City" className={styles.inputForm} />
              </Form.Item>
            </Col>{' '}
            <Divider
              style={{
                borderTop: '1.1px solid #CCCCD6',
                margin: '0px 0 15px',
              }}
            />
            {error && (
              <p style={{ color: red, marginBottom: '15px' }}>{error}</p>
            )}
            <Col span={24}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Button
                  type="primary"
                  loading={isLoading}
                  htmlType="submit"
                  style={{ marginBottom: '0px' }}
                  className={styles.formBtnBox}
                >
                  Donate
                </Button>
              </div>
              <Row>
                <p className={styles.securePayment}>
                  Secure Payments <SafetyCertificateOutlined />{' '}
                </p>
                <p className={styles.securePaymentDesc}>
                  All payments are secured by{' '}
                  <a
                    href="https://stripe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe
                  </a>
                  . We do not store your payment information. We care about your
                  security.
                </p>
              </Row>
            </Col>
            <p className={styles.noteText}>
              Note: 80% goes to TS Heroes and 20% goes to TS
            </p>
          </Row>
        </div>
      </div>
    </Form>
  );
};

export default TSDonateForm;
