import { statusOptions, criterionOptions } from '../../utils';

// export const searchReportOptions = (returnAll: boolean) => {
//   const reports = [
//     {
//       label: 'Symbols',
//       value: 'SYMBOL',
//       children: [
//         {
//           label: 'Individual Statistical Report',
//           value: 'INDIVIDUAL_STATISTICAL_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'Trending Stocks Report',
//           value: 'TRENDING_STOCK',
//           hidden: false,
//         },

//         {
//           label: 'Compare Symbols',
//           value: 'COMPARE_SYMBOLS',
//           hidden: false,
//         },
//         {
//           label: 'Verify Symbols VS Institutions',
//           value: 'VERIFY_SYMBOLS_VS_INSTITUTIONS',
//           hidden: false,
//         },
//         {
//           label: 'Moving Averages',
//           value: 'MOVING_AVERAGES',
//           hidden: false,
//         },
//         {
//           label: 'DOW Theory Uptrend',
//           value: 'DOW_THEORY_UPTREND',
//           hidden: false,
//         },
//         {
//           label: 'DOW Theory Downtrend',
//           value: 'DOW_THEORY_DOWNTREND',
//           hidden: false,
//         },
//         {
//           label: 'All Symbols',
//           value: 'ALL_SYMBOLS',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'Institutions',
//       value: 'INSTITUTION',
//       children: [
//         {
//           label: 'Individual Institution Report',
//           value: 'INDIVIDUAL_INSTITUTION_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'Compare Institutions',
//           value: 'COMPARE_INSTITUTIONS',
//           hidden: false,
//         },
//         {
//           label: 'Verify Institutions VS Symbols',
//           value: 'VERIFY_INSTITUTIONS_VS_SYMBOLS',
//           hidden: false,
//         },
//         {
//           label: 'All Institutions Counts By Symbols',
//           value: 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
//           hidden: false,
//         },
//         {
//           label: 'Increased',
//           value: 'INCREASED',
//           hidden: false,
//         },
//         {
//           label: 'No Change',
//           value: 'NO_CHANGE',
//           hidden: false,
//         },
//         {
//           label: 'Decreased',
//           value: 'DECREASED',
//           hidden: false,
//         },
//         {
//           label: 'Newly Bought',
//           value: 'NEWLY_BOUGHT',
//           hidden: false,
//         },
//         {
//           label: 'Sold Off',
//           value: 'SOLD_OFF',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'Top 10 Hedge',
//       value: 'TOP_10_HEDGE',
//       children: [
//         {
//           label: 'Individual Institution Report',
//           value: 'INDIVIDUAL_INSTITUTION_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'Compare Institutions',
//           value: 'COMPARE_INSTITUTIONS',
//           hidden: false,
//         },
//         {
//           label: 'Verify Institutions VS Symbols',
//           value: 'VERIFY_INSTITUTIONS_VS_SYMBOLS',
//           hidden: false,
//         },
//         {
//           label: 'All Institutions Counts By Symbols',
//           value: 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
//           hidden: false,
//         },
//         {
//           label: 'Increased',
//           value: 'INCREASED',
//           hidden: false,
//         },
//         {
//           label: 'No Change',
//           value: 'NO_CHANGE',
//           hidden: false,
//         },
//         {
//           label: 'Decreased',
//           value: 'DECREASED',
//           hidden: false,
//         },
//         {
//           label: 'Newly Bought',
//           value: 'NEWLY_BOUGHT',
//           hidden: false,
//         },
//         {
//           label: 'Sold Off',
//           value: 'SOLD_OFF',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'SC-13D/G',
//       value: 'SEC_13_DG',
//       children: [
//         {
//           label: 'Latest Report',
//           value: 'DAILY_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'By Symbol',
//           value: 'SYMBOL_SUMMARY',
//           hidden: false,
//         },
//         {
//           label: 'All',
//           value: 'DETAIL_SUMMARY_REPORT',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'Sector',
//       value: 'SECTOR',
//       children: [
//         {
//           label: 'Trending',
//           value: 'SECTOR_TRENDING',
//           hidden: false,
//         },
//         {
//           label: 'All Institution',
//           value: 'ALL_INSTITUTIONS',
//           hidden: false,
//         },
//         {
//           label: 'Top 10 Hedge',
//           value: 'TOP_10_HEDGE',
//           hidden: false,
//         },
//         {
//           label: 'Allocations',
//           value: 'ALLOCATIONS',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'SEC Insiders',
//       value: 'SEC_INSIDERS',
//       children: [
//         {
//           label: 'Latest',
//           value: 'INSIDER_DAILY_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'By Symbol',
//           value: 'INSIDER_SYMBOL_SUMMARY',
//         },
//         {
//           label: 'All',
//           value: 'INSIDER_DETAIL_SUMMARY_REPORT',
//           hidden: false,
//         },
//         {
//           label: 'All Cumulative',
//           value: 'INSIDER_ALL_CUMULATIVE_REPORT',
//           hidden: true,
//         },
//       ],
//     },
//     {
//       label: 'Options',
//       value: 'OPTION',
//       children: [
//         {
//           label: 'All Institution',
//           value: 'OPTION_ALL_INSTITUTION',
//           hidden: false,
//         },
//         {
//           label: 'By Institution',
//           value: 'OPTION_BY_INSTITUTION',
//           hidden: false,
//         },
//         {
//           label: 'By Symbol',
//           value: 'OPTION_BY_SYMBOL',
//           hidden: false,
//         },
//       ],
//     },
//     {
//       label: 'Price',
//       value: 'PRICE',
//       hidden: false,
//       children: [],
//     },
//     {
//       label: 'Build Your Own',
//       value: 'BUILD_YOUR_OWN',
//       hidden: false,
//       children: [],
//     },
//   ];
//   return returnAll
//     ? reports
//     : reports.filter((r) => {
//         if (r.children && r.children.length) {
//           r.children = r.children.filter((i) => !i.hidden);
//           return r;
//         } else return r;
//       });
// };

export const criterionOption = [
  {
    name: 'Shares',
    value: 'SHARES',
  },
  {
    name: 'Counts',
    value: 'COUNT',
  },

  {
    name: 'Both Counts & Shares',
    value: 'BOTH',
  },
];

export const standardFields = [
  {
    label: 'Symbols',
    type: 'input',
    size: 24,
  },
  {
    label: 'Sector',
    type: 'sectorDropdown',
    name: 'multiSector',
    data: [],
    placeholder: 'Sector',
    size: 12,
  },
  {
    label: 'Exclude Sector',
    type: 'multi',
    name: 'excludeMultiSector',
    data: [],
    placeholder: 'Exclude Sector',
    size: 12,
  },
  {
    // label: 'Inst Count',
    type: 'range',
    name: 'minInstCount',
    label: 'Min Inst Count',
    placeholder: 'Min',
    size: 12,
  },
  {
    // label: 'Inst Count',
    type: 'range',
    name: 'maxInstCount',
    label: 'Max Inst Count',
    placeholder: 'Max',
    size: 12,
  },
  {
    type: 'range',
    name: 'minCount',
    label: 'MinCount',
    placeholder: 'Min',
    size: 12,
  },
  {
    type: 'range',
    name: 'maxCount',
    label: 'Max Count',
    placeholder: 'Max',
    size: 12,
  },
  {
    type: 'range',
    name: 'minAllocatedPerc',
    label: 'Min Allocated Perc',
    placeholder: 'Min',
    size: 12,
  },
  {
    type: 'range',
    name: 'maxAllocatedPerc',
    label: 'Max Allocated Perc',
    placeholder: 'Max',
    size: 12,
  },

  {
    // label: 'Change in count (%)',
    type: 'range',
    name: 'minInstCountChange',
    label: 'Min Change in count (%)',
    placeholder: 'Min',
    size: 12,
  },

  {
    // label: 'Last Sale',
    type: 'range',
    name: 'minPrice',
    label: 'Min Last Sale',
    placeholder: 'Min',
    size: 12,
  },
  {
    // label: 'Last Sale',
    type: 'range',
    name: 'maxPrice',
    label: 'Max Last Sale',
    placeholder: 'Max',
    size: 12,
  },
  {
    name: 'minPossibleGrowth',
    placeholder: 'Min % Possible Growth',
    label: 'Min % Possible Growth',
    allowDecimal: true,
    size: 12,
    type: 'range',
  },
  {
    name: 'maxPossibleGrowth',
    placeholder: 'Max % Possible Growth',
    label: 'Max % Possible Growth',
    allowDecimal: true,
    size: 12,
    type: 'range',
  },

  {
    // label: 'Change in shares (%)',
    type: 'range',
    name: 'minInstSharesChange',
    label: 'Min Change in shares (%)',
    placeholder: 'Min',
    size: 12,
  },
  {
    // label: 'YTD (%)',
    type: 'range',
    name: 'minYtd',
    label: 'Min YTD (%)',
    placeholder: 'Min',
    size: 12,
  },
  {
    // label: 'YTD (%)',
    type: 'range',
    name: 'maxYtd',
    label: 'Max YTD (%)',
    placeholder: 'Max',
    size: 12,
  },
  {
    // label: '1 Yr Growth (%)',
    type: 'range',
    name: 'minOneYrGrowth',
    label: 'Min 1 Yr Growth (%)',
    placeholder: 'Min',
    size: 12,
  },
  {
    // label: '5 Yr Growth (%)',
    type: 'range',
    name: 'minFiveYrGrowth',
    label: 'Min 5 Yr Growth (%)',
    placeholder: 'Min',
    size: 12,
  },
];

export const advanceRangeFields = [
  {
    // label: 'Prev Inst Count',
    type: 'range',
    name: 'minPrevInstCount',
    label: 'Min Prev Inst Count',
    placeholder: 'Min',
    size: 12,
    children: [
      // {
      // name: 'minPrevInstCount',
      // label: 'Min Prev Inst Count',
      // placeholder: 'Min',
      // },
      // {
      //   name: 'maxPrevInstCount',
      //   label: 'Max Prev Inst Count',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    // label: 'AUM (Assets Under Management)',
    type: 'range',
    name: 'minAum',
    label: 'Min AUM (Assets Under Management)',
    placeholder: 'Min',
    size: 12,
    children: [
      //{
      // name: 'minAum',
      // label: 'Min AUM (Assets Under Management)',
      // placeholder: 'Min',
      //},
      // {
      //   name: 'maxAum',
      //   label: 'Max AUM (Assets Under Management)',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    // label: '52 Wk Low',
    type: 'range',
    name: 'minP52WkLow',
    label: 'Min 52 Wk Low',
    placeholder: 'Min',
    size: 12,
    children: [
      //{
      // name: 'minP52WkLow',
      // label: 'Min 52 Wk Low',
      // placeholder: 'Min',
      // },
      // {
      //   name: 'maxP52WkLow',
      //   label: 'Max 52 Wk Low',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    // label: '52 Wk High',
    type: 'range',
    name: 'minP52WkHigh',
    label: 'Min 52 Wk High',
    placeholder: 'Min',
    size: 12,
    children: [
      // {
      //   name: 'minP52WkHigh',
      //   label: 'Min 52 Wk High',
      //   placeholder: 'Min',
      // },
      // {
      //   name: 'maxP52WkHigh',
      //   label: 'Max 52 Wk High',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    label: 'Institution',
    type: 'input',
    size: 24,
  },
  {
    // label: 'Ownership',
    type: 'range',
    name: 'minOwnership',
    label: 'Min Ownership',
    placeholder: 'Min',
    size: 12,
    children: [
      // {
      //   name: 'minOwnership',
      //   label: 'Min Ownership',
      //   placeholder: 'Min',
      // },
      // {
      //   name: 'maxOwnership',
      //   label: 'Max Ownership',
      //   placeholder: 'Max',
      // },
    ],
  },

  {
    label: 'Status',
    type: 'objectDropdown',
    name: 'status',
    data: statusOptions,
    placeholder: 'Status',
    size: 12,
  },
  {
    label: 'Criterion',
    type: 'dropdown',
    name: 'criterion',
    data: criterionOptions,
    placeholder: 'Criterion',
    size: 12,
  },
  {
    label: 'No of Quarters',
    type: 'dropdown',
    name: 'noOfQuarters',
    data: [2, 3, 4, 5, 6, 7, 8, 9, 10],
    placeholder: 'No of Quarters',
    size: 12,
  },
  {
    // label: 'Market Cap',
    type: 'range',
    name: 'minMarketCap',
    label: 'Min Market Cap',
    placeholder: 'Min',
    size: 12,
    children: [
      // {
      //   name: 'minMarketCap',
      //   label: 'Min Market Cap',
      //   placeholder: 'Min',
      // },
      // {
      //   name: 'maxMarketCap',
      //   label: 'Max Market Cap',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    // label: 'Volume',
    type: 'range',
    name: 'minVolume',
    label: 'Min Volume',
    placeholder: 'Min',
    size: 12,
    children: [
      // {
      //   name: 'minVolume',
      //   label: 'Min Volume',
      //   placeholder: 'Min',
      // },
      // {
      //   name: 'maxVolume',
      //   label: 'Max Volume',
      //   placeholder: 'Max',
      // },
    ],
  },
  {
    label: 'Inst Count Doubled',
    type: 'radio',
    name: 'instCountDoubled',
    size: 12,
  },
  {
    label: 'Inst Shares Doubled',
    type: 'radio',
    name: 'instSharesDoubled',
    size: 12,
  },
  {
    label: 'S&P',
    type: 'radio',
    name: 'isSP',
    size: 12,
  },
  {
    label: '52 W Currently potential',
    type: 'radio',
    name: 'p52WCurrentlyPotential',
    size: 12,
  },
  {
    label: '52 W Future potential',
    type: 'radio',
    name: 'p52WFuturePotential',
    size: 12,
  },
  {
    label: 'Top 10 Hedge funds',
    type: 'input',
    size: 24,
  },
];

// export const searchTypeOptions = [
//   {
//     name: 'Symbols',
//     value: 'SYMBOL',
//   },
//   {
//     name: 'Institutions',
//     value: 'INSTITUTION',
//   },
//   {
//     name: 'Top 10 Hedge',
//     value: 'TOP_10_HEDGE',
//   },
//   {
//     name: 'SEC-13D/G',
//     value: 'SEC_13_DG',
//   },
//   {
//     name: 'Sector',
//     value: 'SECTOR',
//   },
//   {
//     name: 'Price',
//     value: 'PRICE',
//   },
//   {
//     name: 'SEC Insiders',
//     value: 'SEC_INSIDERS',
//   },
//   {
//     name: 'Build Your Own',
//     value: 'BUILD_YOUR_OWN',
//   },
// ];

// export const symbolSearchOptions = [
//   {
//     name: 'Individual Statistical Report',
//     value: 'INDIVIDUAL_STATISTICAL_REPORT',
//   },
//   {
//     name: 'Compare Symbols',
//     value: 'COMPARE_SYMBOLS',
//   },
//   {
//     name: 'Verify Symbols VS Institutions',
//     value: 'VERIFY_SYMBOLS_VS_INSTITUTIONS',
//   },
//   {
//     name: 'Moving Averages',
//     value: 'MOVING_AVERAGES',
//   },
//   {
//     name: 'DOW Theory Symbols',
//     value: 'DOW_THEORY_SYMBOLS',
//   },
//   {
//     name: 'All Symbols',
//     value: 'ALL_SYMBOLS',
//   },
// ];

// export const daysAgo = [
//   {
//     name: 'All',
//     value: 'All',
//   },
//   {
//     name: '1 Day Ago',
//     value: '1 Day Ago',
//   },
//   {
//     name: '5 Day Ago',
//     value: '5 Day Ago',
//   },
//   {
//     name: '10 Day Ago',
//     value: '10 Day Ago',
//   },
//   {
//     name: '15 Day Ago',
//     value: '15 Day Ago',
//   },
//   {
//     name: '20 Day Ago',
//     value: '20 Day Ago',
//   },
//   {
//     name: '25 Day Ago',
//     value: '25 Day Ago',
//   },
// ];

// export const institutionSearchOptions = [
//   {
//     name: 'Individual Institution Report',
//     value: 'INDIVIDUAL_INSTITUTION_REPORT',
//   },
//   {
//     name: 'Compare Institutions',
//     value: 'COMPARE_INSTITUTIONS',
//   },
//   {
//     name: 'Verify Institutions VS Symbols',
//     value: 'VERIFY_INSTITUTIONS_VS_SYMBOLS',
//   },
//   {
//     name: 'All Institutions Counts By Symbols',
//     value: 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
//   },
//   {
//     name: 'Increased',
//     value: 'INCREASED',
//   },
//   {
//     name: 'No Change',
//     value: 'NO_CHANGE',
//   },
//   {
//     name: 'Decreased',
//     value: 'DECREASED',
//   },
//   {
//     name: 'Newly Bought',
//     value: 'NEWLY_BOUGHT',
//   },
//   {
//     name: 'Sold Off',
//     value: 'SOLD_OFF',
//   },
// ];

// export const sectorSearchOptions = [
//   {
//     name: 'All Institution',
//     value: 'ALL_INSTITUTIONS',
//   },
//   {
//     name: 'Top 10 Hedge',
//     value: 'TOP_10_HEDGE',
//   },
//   {
//     name: 'ALLOCATIONS',
//     value: 'ALLOCATIONS',
//   },
// ];
// export const sec13DGSearchOptions = [
//   {
//     name: 'Today Report',
//     value: 'DAILY_REPORT',
//   },
//   {
//     name: 'By Symbol',
//     value: 'SYMBOL_SUMMARY',
//   },
//   {
//     name: 'From JAN 2020',
//     value: 'DETAIL_SUMMARY_REPORT',
//   },
// ];
