import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Select, message } from 'antd';
import axios from 'axios';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  CartesianGrid,
  Dot,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { esSearchActiveCompany } from '../../graphql';
import { green } from '../../utils';
import styles from '../picks/style.module.scss';

const { Option } = Select;

const MyLineChart = ({ history }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkedValues, setCheckedValues] = useState({
    closing: true,
    high: true,
    low: true,
    open: true,
  });
  const [selectedEndTime, setSelectedEndTime] = useState(0);
  const [selectedStartTime, setSelectedStartTime] = useState(0);
  const [timeOptions, setTimeOptions] = useState([]);
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchActiveCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };
  const [trendType, setTrendType] = useState('5 mins');

  const urlSplit = window.location.href.split('/');
  const symbol = urlSplit[urlSplit.length - 1];

  useEffect(() => {
    const generateTimeOptions = () => {
      const options = [];
      const start = new Date();
      start.setHours(4, 0, 0, 0);
      const end = new Date();
      end.setHours(20, 0, 0, 0);

      for (
        let time = start.getTime();
        time <= end.getTime();
        time += 30 * 60 * 1000
      ) {
        options.push(Math.floor(time / 1000));
      }
      setTimeOptions(options);
      setSelectedEndTime(Math.floor(end.getTime() / 1000));
      setSelectedStartTime(Math.floor(start.getTime() / 1000));
    };

    generateTimeOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const startTime = selectedStartTime;
      const endTime = selectedEndTime;

      if (startTime >= endTime) {
        if (startTime !== 0 && endTime !== 0) {
          message.error('End Time must be greater than Start Time.');
        }
        setLoading(false);
        return;
      }

      try {
        const resolution = trendType === '1 min' ? 1 : 5;
        const response = await axios.get(
          `https://finnhub.io/api/v1/stock/candle?symbol=${symbol}&resolution=${resolution}&from=${startTime}&to=${endTime}&token=cnsr81hr01qt1mk8sdk0cnsr81hr01qt1mk8sdkg`,
        );

        const apiData = response.data;

        const transformedData = apiData.c.map((closing, index) => {
          const date = new Date(apiData.t[index] * 1000);
          return {
            date: date.toLocaleString('en-US', {
              timeZone: 'America/New_York',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            }),
            dateTimestamp: apiData.t[index],
            closing,
            high: apiData.h[index],
            low: apiData.l[index],
            open: apiData.o[index],
            volume: apiData.v[index],
          };
        });

        setData(transformedData);
      } catch (error) {
        setError(
          error.message || 'Error fetching data. Please try again later.',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol, selectedStartTime, selectedEndTime, trendType]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValues((prev) => ({ ...prev, [name]: checked }));
  };

  const handleEndTimeChange = (value) => {
    setSelectedEndTime(Number(value));
  };

  const handleStartTimeChange = (value) => {
    setSelectedStartTime(Number(value));
  };

  const closingData = data.map((d) => d.closing);
  const highData = data.map((d) => d.high);
  const lowData = data.map((d) => d.low);
  const openData = data.map((d) => d.open);

  const minY = Math.min(
    ...closingData.filter((v) => v !== undefined),
    ...highData.filter((v) => v !== undefined),
    ...lowData.filter((v) => v !== undefined),
    ...openData.filter((v) => v !== undefined),
  );

  const maxY = Math.max(
    ...closingData.filter((v) => v !== undefined),
    ...highData.filter((v) => v !== undefined),
    ...lowData.filter((v) => v !== undefined),
    ...openData.filter((v) => v !== undefined),
  );

  return (
    <div style={{ minHeight: '90vh', marginTop: '120px', paddingLeft: '50px' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <BounceLoader color={green} loading={loading} size={150} />
        </div>
      ) : (
        <>
          {error && (
            <div
              style={{
                minHeight: '90vh',
                paddingTop: 300,
                textAlign: 'center',
                fontSize: 20,
                color: 'red',
              }}
            >
              Day Trend Graph is Currently Not Available For {symbol}
            </div>
          )}
          {!error && (
            <>
              <div className={styles.vGoBackSaveBar}>
                <p className={styles.vGoBackBtnBlock}>
                  <span
                    onClick={() => history.goBack()}
                    className={styles.vGoBackBtn}
                  >
                    <ArrowLeftOutlined /> Go Back
                  </span>
                </p>{' '}
                <br />
                <p className={styles.vGoBackBtnBlock}>
                  <span
                    onClick={() =>
                      history.push(
                        '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20',
                      )
                    }
                    className={styles.vGoBackBtn}
                  >
                    <ArrowLeftOutlined /> Go To Trending Stocks Report
                  </span>
                </p>
              </div>
              <h1
                style={{
                  color: '#1abd50',
                  margin: 'auto',
                  paddingBottom: '30px',
                  paddingLeft: 380,
                }}
              >
                Day Trend Graph For #{symbol}
              </h1>

              <div
                style={{
                  marginLeft: 50,
                  marginBottom: 20,
                  width: '85%',
                  display: 'flex',
                  // alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ marginRight: '20px' }}>
                  <label htmlFor="trendSelect">Select Trend Type:</label>
                  <Select
                    id="trendSelect"
                    value={trendType}
                    onChange={(value) => setTrendType(value)}
                    style={{ width: 180 }}
                  >
                    <Option value="1 min">1 Minute Trend</Option>
                    <Option value="5 mins">5 Minute Trend</Option>
                  </Select>
                </div>

                <div style={{ marginRight: '20px' }}>
                  <label htmlFor="startTimeSelect">Select Start Time:</label>
                  <Select
                    id="startTimeSelect"
                    value={selectedStartTime}
                    onChange={handleStartTimeChange}
                    style={{ width: 120 }}
                  >
                    {timeOptions.map((time) => (
                      <Option key={time} value={time}>
                        {new Date(time * 1000).toLocaleString('en-US', {
                          timeZone: 'America/New_York',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div>
                  <label htmlFor="endTimeSelect">Select End Time:</label>
                  <Select
                    id="endTimeSelect"
                    value={selectedEndTime}
                    onChange={handleEndTimeChange}
                    style={{ width: 120 }}
                  >
                    {timeOptions.map((time) => (
                      <Option key={time} value={time}>
                        {new Date(time * 1000).toLocaleString('en-US', {
                          timeZone: 'America/New_York',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label htmlFor="symbol">Symbol:</label>
                  <Select
                    placeholder="Search symbol"
                    style={{ width: 180 }}
                    className={styles.vPortStockSearchBar}
                    // ode="multiple"
                    notFoundContent={
                      fetching ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                    onSearch={fetchSymbols}
                    onChange={(symbol) => history.push(`/daytrend/${symbol}`)}
                    filterOption={false}
                    showSearch
                    allowClear={true}
                    size="middle"
                    showArrow={false}
                  >
                    {symbolData.map((d) => (
                      <Option key={d.symbol} className={styles.optionStyle}>
                        <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '20px',
                    marginRight: '20px',
                  }}
                >
                  {['closing', 'high', 'low', 'open'].map((key) => (
                    <label key={key}>
                      <input
                        type="checkbox"
                        name={key}
                        checked={checkedValues[key]}
                        onChange={handleCheckboxChange}
                        aria-label={`Show ${
                          key.charAt(0).toUpperCase() + key.slice(1)
                        }`}
                      />
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </label>
                  ))}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <ResponsiveContainer width="90%" height={400}>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        tick={{
                          textAnchor: 'middle',
                          fill: '#1abd50',
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}
                      />
                      <YAxis
                        tick={{
                          textAnchor: 'middle',
                          fill: '#1abd50',
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}
                        tickMargin={20}
                        domain={[minY, maxY]}
                      />
                      <Tooltip
                        content={({ payload }) => {
                          if (payload && payload.length) {
                            const dateTimestamp =
                              payload[0].payload.dateTimestamp;
                            const date = new Date(dateTimestamp * 1000);
                            return (
                              <div className="custom-tooltip">
                                <p>{`Date: ${date.toLocaleDateString(
                                  'en-US',
                                )}`}</p>
                                <p>{`Time: ${date.toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                })}`}</p>
                                {payload.map((entry, index) => (
                                  <p
                                    key={`item-${index}`}
                                    style={{ color: entry.color }}
                                  >
                                    {`${entry.name}: ${entry.value}`}
                                  </p>
                                ))}
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                      <Legend />
                      {checkedValues.closing && (
                        <Line
                          type="monotone"
                          dataKey="closing"
                          stroke="#8884d8"
                          dot={<Dot r={1} />}
                        />
                      )}
                      {checkedValues.high && (
                        <Line
                          type="monotone"
                          dataKey="high"
                          stroke="#ffc658"
                          dot={<Dot r={1} />}
                        />
                      )}
                      {checkedValues.low && (
                        <Line
                          type="monotone"
                          dataKey="low"
                          stroke="#ff7300"
                          dot={<Dot r={1} />}
                        />
                      )}
                      {checkedValues.open && (
                        <Line
                          type="monotone"
                          dataKey="open"
                          stroke="#82ca9d"
                          dot={<Dot r={1} />}
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyLineChart;
